.home {
}

.home__hero {
  position: relative;
  overflow: hidden;

  @include respond-to(smartphone-large) {
    overflow: visible;
  }

  p {
    max-width: 40%;

    @include respond-to(tablet) {
      max-width: 60%;
    }
    @include respond-to(smartphone-large) {
      max-width: 100%;
    }
  }

  h1 + p {
    @include respond-to(tablet) {
      display: none;
    }
  }

  .button {
    @include respond-to(smartphone-large) {
      position: absolute;
      bottom: 5vh;
    }
  }

  .hero__side {
    position: absolute;
    transition: transform 0.38s cubic-bezier(.66,.02,.48,1);
    width: 70%;

    @include respond-to(smartphone-large) {
      min-height: calc((100vh - 68px) / 2);
      padding-bottom: 85px;
      position: relative;
      width: 100%;
    }

    .hero__videoOverlay {
      background: #000;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      top: 0;
      transition: opacity 0.38s;
      transform: translate3d(-5%, 0, 0);
      width: 110%;

      @include respond-to(smartphone-large) {
        transform: translate3d(0, 0, 0);
        width: 100%;
      }
    }

    .hero__videoBg {
      background: transparent;
      transform: translate3d(-14.3%, 0, 0);
      transition: transform 0.38s cubic-bezier(.66,.02,.48,1);

      @include respond-to(smartphone-large) {
        display: none;
        transform: translate3d(0, 0, 0);
      }
    }

    .hero__mobileBg {
      display: block;
      z-index: -2;
    }

    video {
      transform: translate3d(-50%, -50%, 0);
      // override vertical centering to avoid legal text
      // getting cut off at bottom
      @media only screen and (max-height: 800px) and (min-width: 1025px) {
        transform: translate3d(-50%, -52%, 0);
      }
      @media only screen and (max-height: 700px) and (min-width: 1025px) {
        transform: translate3d(-50%, -58%, 0);
      }
      @include respond-to(smartphone-large) {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  a.hero--ready.is-inactive {
    .hero__videoOverlay {
      opacity: 0.7;
    }
  }

  a:nth-child(1) {
    .hero__mobileBg {
      background-position: top right;
    }
  }

  a.hero--ready:nth-child(1) {
    &:hover {
      ~ a {
        .hero__side {
          transform: translate3d(57%, 0, 0);

          @include respond-to(smartphone-large) {
            transform: translate3d(0, 0, 0);
          }
        }
      }

      .hero__videoBg {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  a:nth-child(2) {
    &.hero--ready:hover {
      .hero__side {
        transform: translate3d(0%, 0, 0);

        .hero__videoBg {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .hero__side {
      right: 0;
      transform: translate3d(28.6%, 0, 0);

      @include respond-to(smartphone-large) {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.home__whatIs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
  min-height: auto;

  @include respond-to(tablet) {
    padding-bottom: 70px;
  }

  @include respond-to(smartphone) {
    padding-bottom: 40px;
  }

  .section__imageContainer {
    padding: 0 10px;

    @include respond-to(smartphone) {
      padding: 0;
      position: absolute;
      top: 70px;
      right: 0;
      width: 50%;
    }

    &:first-child {
      left: 0;
    }

    .section__imageCaption {
      font-size: 26px;
      font-weight: 100;
      letter-spacing: 0;
      text-transform: initial;

      @include respond-to(smartphone) {
        font-size: 17px;
        max-width: 150px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  img {
    height: 500px;
    width: 450px;

    @include respond-to(tablet) {
      height: auto;
      width: 100%;
    }
  }

  .section__content {
    @include respond-to(tablet) {
      padding: 0 20px;
      text-align: center;
      width: 330px;
    }

    @include respond-to(smartphone) {
      padding: 0;
      text-align: center;
      width: auto;
    }
  }

  h2 {
    @include respond-to(smartphone) {
      margin-bottom: 75vw;
      margin-bottom: calc(40vw * (167/150) + 108px)
    }
  }

  p {
    @include respond-to(smartphone) {
      font-size: 17px;
      text-align: left;
    }
  }
}

.home__steps {
  background: $lightest-gray;

  @include respond-to(tablet) {
    padding-top: 70px;
  }

  @include respond-to(smartphone) {
    padding: 40px 10%;
  }

  .button {
    @include respond-to(tablet) {
      display: inline-block;
    }
  }
}

.home__cards {
  background: $lightest-gray;

  .card--standard {
    height: 370px;

    @include respond-to(tablet) {
      height: 310px;
    }

    @include respond-to(smartphone) {
      height: 50vw;
      min-height: 240px;
    }
  }

  .card__mobileImage {
    background-position: bottom right;
    background-size: auto 120%;
    display: none;

    @include respond-to(tablet) {
      display: block;
    }
  }

  a:nth-child(1) {
    .card__image {
      background-position: bottom right;

      @media only screen and (max-width: 450px) {
        background-size: 85% auto;
      }
    }
  }

  a:nth-child(2) {
    p {
      max-width: 40%;

      @include respond-to(smartphone) {
        max-width: 50%;
      }
    }
  }

  a:nth-child(3),
  a:nth-child(4) {
    .card__image:not(.card__mobileImage) {
      background-size: auto 100%;

      @include respond-to(tablet) {
        display: none;
      }
    }

    h2 {
      width: 300px;

      @include respond-to(tablet) {
        width: 280px;
      }

      @include respond-to(smartphone) {
        width: 240px;
      }
    }
  }
}