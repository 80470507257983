.speakers__hero {
  @extend .tv__hero;

  .hero__mobileBg {
    background-position: top center;
  }
}


.speakers__whatIs {
  background-position: 80% 50%;

  .section__inner {
    height: $section-height - 120;
    justify-content: flex-start;
  }

  h2 {
    width: 430px;

    @include respond-to(tablet) {
      width: auto;
    }
  }

  @media only screen and (max-width: 1070px) {
    background-image: url(../images/speakers/what-is-chromecast.jpg) !important;
    background-position: 100% 200px;
    background-size: contain;

    .section__imageBg {
      display: none;
    }

    .section__inner.section__inner--left {
      display: block;
      margin: 0 auto;
    }

    .section__content {
      width: auto;
    }
  }

  @media only screen and (max-width: 860px) {
    background-position: bottom right;
  }
}

.speakers__tour {
  background-position: left center;

  @media only screen and (min-width: 769px) and (max-width: 1300px) {
    background-position: -200px center;
  }

  .section__inner {
    height: $section-height - 120;
    justify-content: flex-end;

    @media only screen and (min-width: 769px) and (max-width: 950px) {
      height: 55vw;
    }
  }

  @include respond-to(smartphone) {
    .section__inner {
      height: 125vw;
      min-height: 550px;
    }

    h2 {
      @include center(x);
      position: absolute;
      top: 30px;
      width: 100%;
    }

    p {
      @include center(x);
      background: white;
      bottom: 30px;
      padding: 30px;
      width: 100%;
    }
  }
}

.speakers__apps {
  @extend .tv__apps;

  .section__appsImage {
    bottom: -112px;
    height: 725px;

    @include respond-to(tablet) {
      bottom: -84px;
      height: 540px;
    }

    @include respond-to(smartphone) {
      bottom: -56px;
      height: 360px;
    }
  }

  @include respond-to(smartphone) {
    // background-position: 50% 50px;

    h2 {
      color: white;
    }

    .button {
      color: $blue;
    }
  }
}

.speakers__learn {
  @extend .tv__learn;
  background: white;
}

.speakers__cards {
  min-height: auto;
  min-height: initial;

  .card__mobileExpandedDetails {
    background: $lightest-gray;
  }
}

.speakers__party {

  .section__inner {
    height: $section-height - 120;
    justify-content: flex-end;
  }

  .section__content {
    background: white;
    box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
    padding: 30px 40px 50px;
    width: 500px;
  }

  @include respond-to(smartphone) {
    .section__inner.section__inner--right {
      height: 550px;
      margin-right: 0;
    }

    .section__content {
      width: auto;
    }
  }

  @include respond-to(smartphone) {
    .section__inner {
      height: 550px;
    }

    .section__content {
      background: none;
      box-shadow: none;
    }

    h2 {
      @include center(x);
      position: absolute;
      top: 30px;
      width: 100%;
    }

    p {
      @include center(x);
      background: white;
      bottom: 30px;
      padding: 30px;
      width: 100%;
    }
  }
}

.speakers__buy {
  @extend .tv__buy;

  display: flex;

  .section__imageContainer {
    height: 586px;

    @include respond-to(smartphone) {
      height: auto;
    }
  }

  .section__inner {
    width: 100%;
  }

  img {
    height: auto;
    margin-right: 100px;
    width: 345px;

    @media only screen and (min-width: 769px) and (max-width: 1300px) {
      width: 30vw;
    }
  }

  .section__content {
    width: 700px;
  }

  @include respond-to(tablet) {
    .section__content {
      width: auto;
    }
  }

  @include respond-to(smartphone) {
    img {
      margin: 250px 0 0 0;
      width: 60%;
    }
  }
}

