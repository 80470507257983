section {
  min-height: $section-height;
  padding: 50px 30px 70px;

  @include respond-to(tablet) {
    min-height: auto;
    min-height: initial;
  }

  @include respond-to(smartphone) {
    padding: 40px 10%;
  }
}

.section--standard {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;

  .section__mobileBg {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include respond-to(smartphone) {
      display: block;
    }
  }

  .section__inner {
    align-items: center;
    display: flex;
    justify-content: center;

    @include respond-to(smartphone) {
      display: block;
      position: relative;
      z-index: 1;
    }

    &.section__inner--left {
      justify-content: flex-start;
      margin-left: 10%;

      @media only screen and (min-width: 2000px) {
        margin-left: 20%
      }

      @media only screen and (max-width: 1400px) {
        margin-left: 5%
      }

      @include respond-to(smartphone) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    &.section__inner--right {
      justify-content: flex-end;
      margin-right: 10%;

      @media only screen and (min-width: 2000px) {
        margin-right: 20%
      }

      @media only screen and (max-width: 1400px) {
        margin-right: 5%
      }

      @include respond-to(smartphone) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .section__content {
    flex-shrink: 0;
    width: 420px;
    z-index: 1;

    p {
      margin-top: 20px;
    }

    @include respond-to(smartphone) {
      width: auto;
    }
  }

  .button {
    @extend .button--light;
    display: inline-block;
    margin: 25px 0;
  }

  .section__imageCaption {
    @extend %smallCaps;
    color: $blue;
    font-size: 14px;
    text-align: center;
  }

  .section__imageItemUnavailable {
    font-size: 14px;
    text-align: center;
  }

  .section__imageBg {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .section__videoBg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;

    video {
      max-height: 100%;
      max-width: 100%;
      position: relative;
    }
  }

  .section__appsStores {
    bottom: 182px;
    left: calc(10% + 18px);
    position: absolute;

    @include respond-to(tablet) {
      bottom: 308px;
    }

    @media only screen and (min-width: 2000px) {
      left: calc(20% + 10px);
    }

    .section__appsStoresAppStore {
      @extend .svg-chromecast-app-applestore;
      @extend .svg-chromecast-app-applestore-dims;
      float: left;
      transform: scale(1.5);
      transform-origin: 0 0;
    }

    .section__appsStoresPlayStore {
      @extend .svg-chromecast-app-googleplay;
      @extend .svg-chromecast-app-googleplay-dims;
      float: left;
      margin-left: 52px;
      transform: scale(1.5);
      transform-origin: 0 0;
    }
  }

}

.section--cards, .section--expandableCards {
  font-size: 0;
  padding: 20px 0;
}

.section--videoCards {
  padding: 20px 0;

  @include respond-to(smartphone-large) {
    padding: 60px 0;
  }
}

.section--steps {
  padding-top: 100px;
  text-align: center;

  @include respond-to(smartphone) {
    padding-top: 60px;
    min-height: auto;
    overflow: hidden;
  }

  .section__steps {
    align-items: top;
    display: flex;
    justify-content: center;
    margin: 50px 0;

    @include respond-to(smartphone) {
      display: block;
      margin: 40px 0;
    }
  }

  .section__step {
    flex: 1;
    padding: 0 40px;
    position: relative;

    @include respond-to(tablet) {
      padding: 0 20px;
    }
    @include respond-to(smartphone) {
      padding: 0 5px;
      width: 80%;
    }

    &:before {
      background: url(../images/shared/dotted-line.png) repeat-x center center;
      background-size: 324px 3px;
      content: '';
      display: block;
      height: 10px;
      left: calc(-40% + 100px);
      position: absolute;
      top: 187px;
      width: calc(80% - 200px);

      @include respond-to(tablet) {
        left: calc(-40% + 75px);
        top: calc((30vw - 40px) / 2 - 5px);
        width: calc(80% - 150px);
      }

      @include respond-to(smartphone) {
        display: none;
      }
    }
    &:first-child:before {
      display: none;
    }
  }

  .section__stepImage {
    border-radius: 50%;
    height: 375px;
    margin-bottom: 30px;
    width: 375px;
    max-height: calc(30vw - 40px);
    max-width: calc(30vw - 40px);

    @include respond-to(tablet) {
      height: auto;
    }

    @include respond-to(smartphone) {
      height: auto;
      margin: 0 auto 15px;
      max-height: auto;
      max-height: initial;
      max-width: auto;
      max-width: initial;
      width: 94%;
    }
  }

  .section__stepContent {
    @include respond-to(smartphone) {
      background: #fff;
      padding: 20px 20px 40px;
    }
  }

  .section__stepHeader {
    margin-bottom: 0.6em;
  }

  .section__stepDescription {
    @extend %normalType;
    color: $darker-gray;
    font-size: 14px;
    margin: 0 auto;
    max-width: 300px;
  }

  .section__stepLink {
    display: inline-block;
    font-size: 14px;
    margin: 15px -10px 0 0;

    &:after {
      content: '›';
      margin: 0 7px;
    }
  }

  .slick-dots {
    bottom: 12px;
    display: none;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    @include respond-to(smartphone) {
      display: block;
    }

    li {
      padding: 5px;
      &.slick-active {
        button:before {
          background: $light-gray;
        }
      }

      button:before {
        border-color: $light-gray;
      }
    }
  }

  .slick-slider {
    .slick-list {
      overflow: visible;
    }
  }
}

.section--resources {
  min-height: auto;
  padding: 70px 30px;
  text-align: center;

  @include respond-to(smartphone) {
    padding: 60px 10%;
  }

  p {
    margin: 10px 0 60px;
  }

  .section__resources {
    align-items: top;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1080px;

    @include respond-to(smartphone) {
      display: block;
    }
  }

  .section__resource {
    flex: 1;
    padding: 0 40px;

    @include respond-to(smartphone) {
      display: block;
      padding: 0 40px 40px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:hover {
      h4 {
        color: $blue;
      }
    }

    &:before {
      background-color: $blue !important;
      border-radius: 50%;
      content: '';
      display: block;
      height: 55px;
      margin: 0 auto 20px;
      width: 55px;
    }

    &.icon-help-center:before {
      @extend .svg-chromecast-resources-helpcenter;
      @extend .svg-chromecast-resources-helpcenter-dims;
    }
    &.icon-help-forum:before {
      @extend .svg-chromecast-resources-helpforum;
      @extend .svg-chromecast-resources-helpforum-dims;
    }
    &.icon-trouble:before {
      @extend .svg-chromecast-resources-trouble;
      @extend .svg-chromecast-resources-trouble-dims;
    }

    h4 {
      color: $dark-gray;
      transition: color .3s;
    }
  }
}

.section--chromecastApp.section--standard {
  padding-bottom: 50px;

  @include respond-to(smartphone) {
    padding-bottom: 0;
    min-height: auto;
  }

  .section__inner {
    align-items: flex-start;
    justify-content: center;
    margin: 0;

    @include respond-to(smartphone) {
      display: block;
    }
  }

  .section__imageContainer {
    @include respond-to(tablet) {
      left: 50%;
      position: absolute;
      transform: translate3d(-75px, 0, 0);
    }

    @include respond-to(smartphone) {
      display: none;
    }
  }

  .section__content {
    margin-top: 60px;
    min-height: 650px;

    @include respond-to(tablet) {
      margin-right: 330px;
    }

    @include respond-to(smartphone) {
      margin: 0 auto !important;
      max-width: 320px;
      min-height: initial;
      width: auto;
    }
  }
}

.section--chromecastAppLearn.section--standard {
  .section__imageContainer {
    width: 600px;

    img {
      height: 740px;
      width: 676px;
    }
  }
}

.section--chromecastAppApps.section--standard {
  .section__content {
    @include respond-to(tablet) {
      margin-right: 330px;
    }
  }

  .section__imageContainer {
    width: 700px;

    @include respond-to(smartphone) {
      display: none;
    }

    img {
      height: 773px;
      width: 801px;
    }
  }

  .section__app {
    .section__appScreenshots {
      transform: translate3d(-140px, 0, 0);

      @include respond-to(tablet) {
        transform: translate3d(-75px, 0, 0);
      }

      img {
        height: 773px;
        width: 801px;
      }
    }

    .section__appComingSoon {
      transform: translate3d(400px, -30px, 0);

      @include respond-to(tablet) {
        transform: translate3d(130px, -60px, 0);
      }
      @include respond-to(smartphone) {
        transform: translate3d(-170px, 114px, 0);
      }
    }
  }
}

.section__compatibility {
  margin-top: 50px;

  .section__compatibilityIcon {
    margin: -10px 0;
  }

  .section__compatibilityLabel {
    @extend %smallCaps;
    color: $blue;
    font-size: 14px;
  }

  .section__compatibilityFootnote {
    @extend %normalType;
    clear: left;
    color: $dark-gray;
    font-size: 12px;
    padding-top: 20px;
  }

  .section__compatibilityIconLabel {
    @extend %normalType;
    color: $darker-gray;
    font-size: 9px;
    margin-top: -20px;
    text-align: center;
  }

  .section__compatibilityItem {
    margin-bottom: 17px;
  }

  .section__compatibilityIconsContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 15px 0;
    max-width: 300px;
  }
}

.section__compatible {
  float: left;
  font-size: 10px;
  text-align: center;

  @include respond-to(smartphone) {
    margin-top: -10px;
  }

  &:before {
    content: '';
    display: block;
    transform: scale(1.6);

    @include respond-to(smartphone) {
      margin-bottom: -20px;
      transform: scale(1);

    }
  }

  &.icon-audio-rga {
    @include respond-to(smartphone) {
      transform: translate3d(-15px, 0, 0);
    }

    &:before {
      @extend .svg-audio-inputs-RCA-blue;
      @extend .svg-audio-inputs-RCA-blue-dims;
    }
  }
  &.icon-audio-35mm {
    margin-left: 12px;

    @include respond-to(smartphone) {
      margin-left: 0;
      transform: translate3d(-30px, 0, 0);
    }

    &:before {
      @extend .svg-audio-inputs-35-blue;
      @extend .svg-audio-inputs-35-blue-dims;
    }
  }
  &.icon-audio-optical {
    @include respond-to(smartphone) {
      transform: translate3d(-45px, 0, 0);
    }

    &:before {
      @extend .svg-audio-inputs-OPTICAL-blue;
      @extend .svg-audio-inputs-OPTICAL-blue-dims;
    }
  }
}

.section__tour {
  position: relative;
}

.section__tourSteps {
  height: 200px;

  &--mobile {
    bottom: 30px;
    display: none;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @include respond-to(smartphone-and-touch) {
    height: auto;

    &--mobile {
      display: block;
    }

    &--desktop {
      display: none;
    }
  }
}

.section__tourStep {
  @extend %thinType;
  color: $dark-gray;
  font-size: 17px;
  margin-top: 20px;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50px);
  width: 420px;

  &.is-active {
    opacity: 1;
    transition: opacity $transition-med, transform $transition-med;
    transform: none;
  }

  @include respond-to(tablet) {
    width: 300px;
  }

  @include respond-to(smartphone-and-touch) {
    font-size: 13px;
    font-weight: $opensans-regular;
    margin: 0 10px;
    opacity: 1;
    padding-top: 400px;
    position: relative;
    transform: none;
    top: auto;
  }
}

.section__tourStepImages {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  @include respond-to(smartphone-and-touch) {
    display: block;
  }
}

.section__tourStepImage {
  @include center(x);
  background-position: center center;
  background-size: cover;
  height: 100%;
  max-width: 690px;
  opacity: 0;
  top: 15vw;
  transition: opacity 0.5s;
  width: 100%;

  &.is-active {
    opacity: 1;
  }
}

.section__tourStepContent {
  @include respond-to(smartphone-and-touch) {
    background: white;
    padding: 40px 20px 20px;
    position: relative;
  }
}

.section__tourStepIcon {
  float: left;
  margin: -10px;
}

.section__tourStepBreadcrumbs {
  margin-top: 10px;
  text-align: center;
}

.section__tourStepBreadcrumb {
  @extend .card__breadcrumb;
}

.section__tourProgress {
  border-bottom: 2px solid $lighter-gray;
  border-top: 2px solid $lighter-gray;
  bottom: -60px;
  font-size: 0;
  height: 60px;
  position: absolute;
  width: 420px;

  @include respond-to(tablet) {
    width: 300px;
  }

  @include respond-to(smartphone-and-touch) {
    display: none;
  }
}

.section__tourProgressIndicatorBg {
  background-color: white;
  height: 100%;
  position: absolute;
  top: 0;
  transition: transform $transition-med $easing;
  width: 140px;

  &--1 {
    transform: translateX(139px);
    width: 138px;
  }

  &--2 {
    transform: translateX(278px);
    width: 142px;
  }

  @include respond-to(tablet) {
    width: 100px;

    &--1 {
      transform: translateX(99px);
      width: 98px;
    }

    &--2 {
      transform: translateX(198px);
      width: 102px;
    }
  }
}

.section__tourProgressIndicator {
  background-color: gray;
  border-bottom: 2px solid $blue;
  bottom: -2px;
  position: absolute;
  transition: transform $transition-med;
  width: 140px;

  &--1 {
    transform: translateX(140px);
    width: 137px;
  }

  &--2 {
    transform: translateX(277px);
    width: 143px;
  }

  @include respond-to(tablet) {
    width: 100px;

    &--1 {
      transform: translateX(100px);
      width: 97px;
    }

    &--2 {
      transform: translateX(197px);
      width: 103px;
    }
  }
}

.section__tourProgressStep {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  position: relative;
  width: 33%;
  z-index: 1;

  &:nth-child(2) {
    border-left: 2px solid $lighter-gray;
    border-right: 2px solid $lighter-gray;
  }

  .section__tourProgressIcon,   .section__tourProgressIcon--active {
    transition: opacity $transition-sm;
  }

  .section__tourProgressIcon--active {
    opacity: 0;
  }

  &.is-active {
    .section__tourProgressIcon--active {
      opacity: 1;
    }

    .section__tourProgressIcon {
      opacity: 0;
    }
  }
}

.section__tourProgressIcon, .section__tourProgressIcon--active {
  @include center(xy);
}

.section__app {
  margin-top: 40px;

  @include respond-to(smartphone) {
    margin: 20px 0 0;
    min-width: 269px;
    overflow: auto;
  }

  .section__appFeatures {
    @include respond-to(smartphone) {
      display: none;
    }
  }

  .section__appFeature {
    border-bottom: solid 2px $lightest-gray;
    padding: 20px 0 0;

    &:before {
      content: '';
      float: left;
      filter: grayscale(1);
      margin: -15px 15px 20px 0;
      transition: filter .3s;
    }

    &.icon-phone:before {
      @extend .svg-chromecast-app-whats-on-all2-blue;
      @extend .svg-chromecast-app-whats-on-all2-blue-dims;
    }
    &.icon-remote:before {
      @extend .svg-chromecast-app-remote2-blue;
      @extend .svg-chromecast-app-remote2-blue-dims;
    }
    &.icon-apps:before {
      @extend .svg-chromecast-app-getapps-blue;
      @extend .svg-chromecast-app-getapps-blue-dims;
    }
    &.icon-search:before {
      @extend .svg-app-search-blue;
      @extend .svg-app-search-blue-dims;
    }

    &:first-child {
      .section__appFeatureContent {
        display: block;
      }
    }

    &:not(.is-open) {
      .section__appFeatureContent {
        height: 0 !important;
      }
    }

    &.is-open {
      &:before {
        filter: grayscale(0);
      }

      h4 {
        color: $blue;
      }
    }

    h4 {
      color: $dark-gray;
      margin-bottom: 18px;
      transition: color .3s;
    }

    .section__appFeatureContent {
      overflow: hidden;
      transform: translate3d(0,0,0);
      transition: height .5s;

      p {
        margin: 0 0 20px;
      }
    }
  }

  .section__appDownload {
    margin-top: 40px;
    position: relative;
    z-index: 1;

    @include respond-to(smartphone) {
      float: right;
      margin: 20px 0 0;
      text-align: center;
      width: 110px;
    }

    &:before {
      @extend .svg-chromecast-app-arrow-grey;
      @extend .svg-chromecast-app-arrow-grey-dims;
      content: '';
      left: 106px;
      position: absolute;
      top: 18px;

      @include respond-to(smartphone) {
        left: 27px;
        top: 85px;
        transform: rotate(90deg);
      }
    }

    .section__appIcon {
      @extend .svg-chromecast-app-gstore;
      @extend .svg-chromecast-app-gstore-dims;
      float: left;

      @include respond-to(smartphone) {
        margin-bottom: 55px;
      }
    }

    h4 {
      left: 175px;
      position: absolute;
      top: 20px;

      @include respond-to(smartphone) {
        clear: both;
        left: 0;
        margin-bottom: 5px;
        position: relative;
        top: 0;
      }
    }

    .section__appAppStore {
      @extend .svg-chromecast-app-applestore;
      @extend .svg-chromecast-app-applestore-dims;
      float: left;
      margin: 37px 0 0 60px;

      @include respond-to(smartphone) {
        margin: 0;
      }
    }

    .section__appPlayStore {
      @extend .svg-chromecast-app-googleplay;
      @extend .svg-chromecast-app-googleplay-dims;
      float: left;
      margin: 37px 0 0 3px;

      @include respond-to(smartphone) {
        margin: -8px 0 10px;
      }
    }
  }

  .section__appScreenshots {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate3d(110px, 167px, 0);

    @include respond-to(tablet) {
      transform: translate3d(125px, 167px, 0);
    }

    @include respond-to(smartphone) {
      display: none;
    }

    .section__appScreenshot {
      left: 0;
      position: absolute;
      top: 0;

      &.is-active {
        img {
          opacity: 1;
        }
      }

      img {
        height: 497px;
        opacity: 0;
        transition: opacity .5s;
        width: 280px;
      }
    }
  }

  .section__appComingSoon {
    align-items: center;
    background: $blue;
    border-radius: 50%;
    box-shadow: 0 17px 50px 0 rgba(0,0,0,0.19);
    color: #fff;
    display: flex;
    font-size: 20px;
    height: 170px;
    left: 50%;
    overflow: hidden;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    top: 50px;
    transform: translate3d(300px, 0, 0);
    width: 170px;

    @include for-langs(en_us) {
      display: none;
    }

    @include respond-to(smartphone-large) {
      transform: translate3d(60px, 0, 0);
    }

    @include respond-to(smartphone) {
      font-size: 16px;
      height: 130px;
      transform: translate3d(-170px, 114px, 0);
      width: 130px;
    }

    span {
      display: inline-block;
      max-width: 130px;

      @include respond-to(smartphone) {
        max-width: 90px;
      }
    }
  }

  .section__appScreenshotMobile {
    bottom: 0;
    display: none;
    float: left;
    position: absolute;
    right: 50%;
    transform: translate3d(30px, 0, 0);

    @include respond-to(smartphone) {
      display: block;
    }

    img {
      float: left;
      height: 286px;
      width: 190px;
    }
  }
}
