@mixin respond-to($point) {
  @if $point == desktop {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }
  @else if $point == tablet {
    @media only screen and (max-width: 1279px) {
      @content;
    }
  }
  @else if $point == smartphone-large {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }
  @else if $point == smartphone-and-touch {
    @media only screen and (max-width: 768px) {
      @content;
    }

    .touch & {
      @content;
    }
  }
  @else if $point == smartphone {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @else if $point == collapseMenu {
    @media only screen and (max-width: 975px) {
      @content;
    }
  }
}

@mixin for-langs($langs...) {
  @each $lang in $langs {
    &:lang(#{$lang}) {
      @content;
    }
  }
}

@mixin center($direction, $position:"absolute") {
  @if $direction == x {
    position: if($position == relative, relative, absolute);
    left: 50%;
    transform: translateX(-50%);
  }
  @else if $direction == y {
    position: if($position == relative, relative, absolute);
    top: 50%;
    transform: translateY(-50%);
  }
  @else if $direction == xy {
    left: 50%;
    position: if($position == relative, relative, absolute);
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
