.button {
  @extend h4;
  background-color: #1EB1FC;
  border: 1px solid #1EB1FC;
  border-radius: 5px;
  color: white;
  padding: 8px 35px 10px;
  text-decoration: none;
  transition: background-color 0.25s, border-color 0.25s, color 0.25s, opacity 1s;
  white-space: nowrap;

  @include respond-to(smartphone) {
    font-size: 12px;
  }
}

.button--light {
  @extend .button;
  background-color: transparent;
  border: 1px solid #D6D6D6;
  color: $blue;

  &:hover {
    background-color: #1EB1FC;
    border: 1px solid #1EB1FC;
    color: white;
  }
}

.button--dark {
  @extend .button;

  &:hover {
    background-color: #00CFFF;
    border-color: #00CFFF;
    color: white;
  }
}

.button--clear {
  @extend .button;
  background-color: transparent;
  border: 1px solid white;
  color: white;

  &:hover {
    background-color: white;
    color: #1EB1FC;
  }
}
