.hero {
  background-size: cover;
  background-position: center center;
  height: 600px;
  height: calc(90vh - 68px);
  padding: 30px 50px;
  position: relative;
  width: 100%;
  
  
  #{$long-winded-langs} {
    @media only screen and (min-width: 1024px) {
      h1 {
        font-size: 70px;
        line-height: 80px;
      }
      p {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  
  @include respond-to(tablet) {
    min-height: 550px;
  }
  @include respond-to(smartphone) {
    height: auto;
    min-height: 0;
    padding: 5vw 10vw 15vw;
  }

  p {
    @extend %thinType;
    color: white;
    font-size: 30px;
    margin-top: 15px;
    max-width: 50%;

    @include respond-to(tablet) {
      max-width: 75%;
    }

    @include respond-to(smartphone) {
      font-size: 17px;
      margin-top: 5px;
      max-width: none;
    }
  }

  .button {
    @extend .button--clear;
    display: inline-block;
    margin-top: 25px;
  }
}

.hero--split {
  font-size: 0;
  overflow: hidden;
  padding: 0;

  @include respond-to(smartphone-large) {
    height: auto;
    min-height: 0;
    overflow: visible;
  }
}

.hero__side,
.hero__sideYoutube {
  background-size: cover;
  background-position: center center;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: transform .3s;
  vertical-align: top;
  width: 50%;

  @include respond-to(smartphone-large) {
    height: auto;
    min-height: 50%;
    padding: 8vw 10vw;
    width: 100%;
  }

  &:nth-child(2) {
    left: 50%;

    @include respond-to(smartphone-large) {
      left: 0;
    }
  }

  &.is-inactive {
    transform: translate3d(100%, 0, 0);

    @include respond-to(smartphone-large) {
      transform: translate3d(0, 0, 0) !important;
    }

    &:first-child {
      transform: translate3d(-100%, 0, 0);
    }
  }
}

.hero__sideYoutube {
  background: #000;
  left: 0 !important;
  padding: 0;
  position: absolute;
  transform: translate3d(50%, 0, 0);
  width: 100%;
  z-index: 1;

  @include respond-to(smartphone-large) {
    height: calc(100vw * (9/16));
    min-height: initial;
    position: relative;
    transform: none !important;
  }

  &:first-child {
    transform: translate3d(-50%, 0, 0);

    @include respond-to(smartphone-large) {
      position: absolute;
      bottom: 0;
    }

    + .hero__side {
      @include respond-to(smartphone-large) {
        margin-bottom: calc(100vw * (9/16));
      }
    }

    iframe {
      transform: translate3d(25%, 0, 0);
    }
  }

  &.is-active {
    transform: translate3d(0, 0, 0);

    iframe {
      transform: translate3d(0, 0, 0);
    }

    .hero__close {
      opacity: 1;
    }
  }

  .hero__close {
    @extend %close-button;
    opacity: 0;
    position: absolute;
    right: 23px;
    top: 16px;
    transition: opacity .3s;
    z-index: 1;

    &:hover {
      &:before, &:after {
        background-color: #fff;
      }
    }
  }

  iframe {
    height: 100%;
    transform: translate3d(-25%, 0, 0);
    transition: transform .3s;
    width: 100%;

    @include respond-to(smartphone-large) {
      float: left;
      transform: none !important;
    }
  }
}

.hero__imageBg {
  background-size: cover;
  background-position: center center;
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: -1;
}

.hero__videoBg {
  background: $darkest-gray;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  @include respond-to(smartphone-and-touch) {
    display: none;
  }

  video {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: opacity .3s;
    
    @include respond-to(smartphone-and-touch) {
      left: 0;
    }

    &.is-hidden {
      opacity: 0;
    }
  }
}

.hero__mobileBg {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  @include respond-to(smartphone-large) {
    display: block;
  }
}

.hero__videoPlayButton {
  @include center(xy);
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  height: 60px;
  width: 60px;

  &:after {
    @include center(y);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid white;
    content: '';
    height: 0;
    left: 24px;
    width: 0;
  }

  @include respond-to(tablet) {
    display: block;
  }
}

.hero__videoPlayLabel {
  @extend %smallCaps;
  @include center(x);
  bottom: -30px;
  color: white;
  font-size: 11px;
}

.hero__arrow {
  display: none;
  @extend %arrow-button;
  bottom: -25px;
  right: 65px;
  position: absolute;

  @include respond-to(smartphone) {
    display: none;
  }
}
