.explore-hero {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(80vh - 68px);
  justify-content: center;
  overflow: hidden;
  padding: 30px 0;
  position: relative;

  @include respond-to(smartphone) {
    height: auto;
  }

  &.tv__hero {
    .hero__content {
      p:last-of-type {
        font-size: 14px;
      }
    }
  }

  &.tv__hero,
  &.speakers__hero {
    @include respond-to(smartphone) {
      background: #fff;
    }

    img {
      height: 549px;
      width: 880px;

      @include respond-to(smartphone) {
        height: auto;
        width: 100%;
      }
    }
  }
  
  &.speakers__hero {
    img {
      width: auto;

      @include respond-to(tablet) {
        height: 580px;
        width: auto;
      }

      @include respond-to(smartphone) {
        height: auto;
        width: 75%;
      }
    }
  }

  .explore-hero__inner {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10%;
    min-height: 100%;

    @media only screen and (min-width: 2000px) {
      margin-left: 20%;
    }

    @media only screen and (max-width: 1400px) {
      margin-left: 5%;
    }

    @include respond-to(smartphone) {
      display: block;
      float: left;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      transform: scaleY(-1);
      z-index: 1;
    }
  }

  h1,
  p {
    color: $explore-gray;
  }

  p {
    max-width: none;
  }

  &.split-left {
    justify-content: flex-start;
    margin: 0 auto;

    @include respond-to(smartphone) {
      margin-left: 0;
      margin-right: 0;
    }

    .hero__content {
      flex-shrink: 0;
      width: 420px;
      z-index: 1;

      @include respond-to(smartphone) {
        display: block;
        float: right;
        padding-bottom: 0;
        position: relative;
        text-align: center;
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        width: 100%;
      }

      p {
        font-size: 24px;

        &:nth-of-type(2) {
          font-size: 40px;
        }

        @include respond-to(smartphone) {
          font-size: 18px;
        }
      }
    }

    .hero__image {
      flex-grow: 1;
      position: relative;
      text-align: center;

      @include respond-to(smartphone) {
        display: block;
        float: right;
        position: relative;
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
      }
    }

    img {
      @media only screen and (min-width: 769px) and (max-width: 1400px) {
        height: auto;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60vw;
      }
    }
  }
}

.explore-tv,
.explore-audio {
  .card__chromecastAppComingSoon {
    display: none;
  }

  .mobileShow {
    display: none;

    @include respond-to(smartphone) {
      display: block;
    }
  }

  .desktopShow {
    display: none;

    &.button {
      @include respond-to(desktop) {
        display: inline-block;
      }
    }

    @include respond-to(desktop) {
      display: block;
    }
  }

  @include respond-to(smartphone) {
    .section__content {
      text-align: center;
      width: 100%;
    }

    .section__compatibilityLabel {
      text-align: left;
    }

    .tv__apps {
      p {
        display: block;
      }

      .section__content {
        background: #fff;
        padding: 30px 40px;
      }
    }
  }

  @include respond-to(smartphone) {
    .tv__tour {
      h2 {
        left: 0;
        padding: 0 10%;
      }
    }
  }

  .tv__whatIs:not(.explore-footer) {
    padding: 200px 0;

    @include respond-to(smartphone) {
      padding: 75px 10%;
    }
  }

  .tv__whatIs,
  .speakers__whatIs {
    min-height: 0;

    @include respond-to(smartphone) {
      padding: 100px 10%;

      .section__content {
        text-align: center;
      }
    }

    img {
      height: 100%;
      width: auto;
      right: 0;

      @include respond-to(smartphone) {
        width: 100%;
        height: auto;
      }
    }

    &.explore-footer {
      border-top: 1px solid $explore-gray;

      @include respond-to(smartphone) {
        .section__inner {
          margin-top: 0;
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }

        .section__content,
        .section__imageContainer {
          display: block;
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }
      }

      h2 {
        @extend h1;
        color: $explore-gray;
      }

      p:nth-of-type(2) {
        display: block;
        font-size: 40px;
        margin-top: 15px;
      }
    }
  }

  .speakers__whatIs {
    padding: 100px 10% 200px;

    .section__imageBg {
      background-position: bottom right;
      background-size: 60%;
    }

    .section__inner {
      height: 440px;
    }
  }

  .tv__whatIs {    
    @include respond-to(smartphone) {
      .section__inner {
        margin-top: 60%;
      }
    }

    &:not(.explore-footer) {
      @include respond-to(smartphone) {
        background-image: url(../images/explore/tv-what-is.jpg) !important;
        background-position: top right;
        background-size: contain;
        height: auto;

        .section__imageBg {
          display: none;
        }
      }
    }
  }

  .tv__apps,
  .audio__apps {
    @include respond-to(smartphone) {
      &.mobileShow .section__inner {
        height: auto;
        min-height: 0;
      }


      &:not(.mobileShow) {
        .section__content {
          background: none;

          h2, p {
            display: none;
          }

          .button {
            display: none;
          }
        }
      }
    }

    span {
      font-weight: 500;

      @include respond-to(smartphone) {
        font-weight: bold;
      }
    }
  }

  .tv__buy,
  .speakers__buy {
    h2 {
      color: $explore-gray;
    }
  }

  .tv__buy {
    .section__imageContainer {
      height: auto;
    }

    p.note {
      display: block;
      font-size: 14px;
      margin-top: 50px;
      padding: 0 0 0 5%;

      @include respond-to(smartphone) {
        text-align: center;
        margin-top: 0;
        padding: 0 10%;
      }
    }

    @include respond-to(smartphone) {
      padding: 40px 0;

      img {
        margin-top: 0;
      }

      .section__content {
        position: static;
      }
    }
  }

  @include respond-to(smartphone) {
    .section--standard:not(.tv__tour):not(.speakers__whatIs):not(.speakers__apps):not(.tv__apps):not(.tv__buy):not(.speakers__buy):not(.speakers__party) {
      .section__inner {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
      }

      .section__content,
      .section__imageContainer {
        display: block;
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
      }
    }
  }

  .tv__cards,
  .audio__cards,
  .tv__learn,
  .audio__learn {
    background: #fff;
  }

  @include respond-to(smartphone) {
    .tv__learn {
      padding-top: 0;
    }
  }
}

.buy__retailers {
  &.explore-retailers {
    padding: 20px 0 40px;
    margin-left: 10%;

    @media only screen and (min-width: 2000px) {
      margin-left: 20%;
    }

    @media only screen and (max-width: 1400px) {
      margin-left: 5%;
    }

    @include respond-to(smartphone) {
      margin-left: 0;
      margin-right: 0;
    }

    @include respond-to(desktop) {
      &:not(.xsell__retailers ) {
        .card--retailer {
          margin: 0 5px 5px 0;
        }
      }
    }

    .cards--caption {
      font-size: 18px;
      margin: 0 auto 15px 0;
      max-width: 1600px;

      @include respond-to(smartphone) {
        padding: 0 15px;
        text-align: center;
      }
    }

    .cards {
      margin: 0;

      @include respond-to(smartphone) {
        margin-left: 3%;
      }
    }

    .card--retailer {
      // todo: refactor, mixin perhaps?
      background: -moz-linear-gradient(bottom, rgba(218,218,218,0.5) 0%, rgba(255,255,255,0) 100%);
      background: -webkit-gradient(linear, left bottom, left top, color-stop(0%,rgba(218,218,218,0.5)), color-stop(100%,rgba(255,255,255,0)));
      background: -webkit-linear-gradient(bottom, rgba(218,218,218,0.5) 0%,rgba(255,255,255,0) 100%);
      background: -o-linear-gradient(bottom, rgba(218,218,218,0.5) 0%,rgba(255,255,255,0) 100%);
      background: -ms-linear-gradient(bottom, rgba(218,218,218,0.5) 0%,rgba(255,255,255,0) 100%);
      background: linear-gradient(to top, rgba(218,218,218,0.5) 0%,rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80dadada', endColorstr='#00ffffff',GradientType=0);
      border: 1px solid #eee;
      height: 70px;

      &.inactive {
        img {
          -webkit-filter: grayscale(1);
          filter: grayscale(1);
          filter: gray;
          opacity: 0.3;
        }
      }

      &:hover {
         box-shadow: 0 5px 15px 0 rgba(0,0,0,0.19);
      }

      @include respond-to(smartphone) {
        display: inline-block;
        width: 45vw !important;
        margin: 5px;
      }
    }

    .card__image {
      height: auto;
      width: auto;
    }
  }
}

.explore-tv {
  @include respond-to(smartphone) {
    .explore-footer {
      img {
        width: 75%;
        height: auto;
      }
    }
  }

  .tv__buy {
    .section__imageContainer img {
      height: auto;
      width: 100%;
    }

    .explore-retailers {
      margin-left: 0;
    }

    .cards[data-columns = "6"] .card {
      width: 12vw;
    }
  }
}

.explore-audio {
  .explore-hero {
    @include respond-to(tablet) {
      img {
        width: 75%;
      }
    }

    @include respond-to(smartphone) {
      height: auto;

      img {
        width: 65%;
      }
    }

    .hero__content {
      padding: 0 !important;
    }
  }

  .explore-hero.split-left {
    img {
      @media only screen and (min-width: 769px) and (max-width: 1400px) {
        height: 549px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
      }
    }
  }

  .tv__whatIs {
    &.explore-footer {
      .section__content {
        position: relative;
      }

      p:last-of-type {
        @include respond-to(smartphone) {
          @include center(x);
          bottom: -40px;
        }

        font-size: 14px;
        position: absolute;
        bottom: -20px;
      }

      .section__imageContainer {
        height: 600px;
      }

      img {
        height: 600px;
      }

      @include respond-to(smartphone) {
        .section__imageContainer {
          height: 374px;
        }

        img {
          height: 374px;
          width: 500px;
        }
      }
    }

    @include respond-to(smartphone) {

      img {
        left: -250px;
        margin-left: 50%;
        position: absolute;
      }
    }
  }

  .tv__buy {
    .explore-retailers {
      margin-left: 0;
    }
  }

  .speakers__buy {
    min-height: 615px;

    @include respond-to(smartphone) {
      h2 {
        padding: 0 10%;
      }
    }



    .section__imageContainer {
      height: 586px;

      @include respond-to(smartphone) {
        height: auto;
      }
    }

    img:not(.card__image) {
      height: 100%;
      width: auto;

      @include respond-to(smartphone) {
        height: auto;
        width: 55vw;
      }
    }

    .card__image {
      margin: auto;
    }
  }

  .speakers__apps {
    h2 {
      color: #333;
    }
  }
}

.landing__buyLightbox {
  background: #fff;
  height: auto;
  min-height: 0;
  min-width: 450px;
  padding: 60px 40px;
  text-align: center;
  width: 50vw;

  h1 {
    color: $explore-gray;
    font-size: 50px;
    line-height: 56px;
    padding: 0 7vw;
  }

  p {
    color: #848484;
    font-size: 18px;
    max-width: none;
    text-align: center;
  }

  .explore-retailers {
    margin: 35px 0 0;
    padding: 0;
  }

  .card__image {
    margin-top: 0;
  }

  .card--retailer {
    height: 70px;
  }
}

@include respond-to(collapseMenu) {
  .touch {
    .explore-header {
      .header__link {
      width: 100%;
      }
    }
  }
}

.touch {
  .explore-hero {
    background-color: #fff;

    .hero__content {
      padding: 10vw 0 0;
    }
  }
}