header {
  background: white;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s $easing 0.4s;
  top: 0;
  width: 100%;
  z-index: 10;

  @include respond-to(collapseMenu) {
    box-shadow: none;
  }

  &.is-hidden {
    transform: translate3d(0, ($header-height * -1), 0);
  }

  &.header--closed {
    height: $header-height;
  }

  .header__logo {
    float: left;
    height: $header-height;
  }

  .header__logoInner {
    @extend .svg-chromecast-logo;
    @extend .svg-chromecast-logo-dims;
    margin: 13px 10px 0 16px;
  }

  .header__top {
    height: $header-height;

    @include respond-to(collapseMenu) {
      background: white;
      position: relative;
      z-index: 2;
    }
  }

  .header__topBuyButton {
    display: none;
    transition: opacity $transition-sm;

    @include respond-to(collapseMenu) {
      @include center(y);
      cursor: pointer;
      display: inline-block;
      padding: 15px 20px 17px;
      right: 65px;
      vertical-align: top;
    }

    @media only screen and (max-width: 400px) {
      display: none;
    }

    &.is-hidden {
      opacity: 0;
    }
  }

  .header__burger {
    cursor: pointer;
    display: none;
    padding: 25px 20px 33px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &:active {
      div,
      div:before,
      div:after {
        background: $blue;
      }
    }

    div {
      background: #77787B;
    }

    @include respond-to(collapseMenu) {
      display: inline-block;
      vertical-align: top;

      div {
        height: 3px;
        margin: 7px 0 0;
        transition: background 0.5s;
        width: 30px;

        &:before,
        &:after {
          background: #77787B;
          content: '';
          display: block;
          height: 3px;
          transition: transform 0.5s, background 0.5s;
          width: 30px;
        }

        &:before {
          transform: translateY(-6px);
          transform-origin: 17px 0px;
        }

        &:after {
          transform: translateY(3px);
          transform-origin: 9px -1px;
        }
      }

      &.open {
        div {
          background: transparent !important;

          &:before {
            transform: rotate3d(0, 0, 1, 45deg);
          }

          &:after {
            transform: rotate3d(0, 0, 1, -45deg);
          }
        }
      }

    }
  }

  .header__bottom {
    @include respond-to(collapseMenu) {
      background: white;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
      z-index: 1;

      &.with-transition {
        transition: transform 0.5s;
      }
    }
  }

  .header__sections {
    position: absolute;
    height: 100%;
    right: 25px;
    top: 0;

    @include respond-to(collapseMenu) {
      height: auto;
      opacity: 0;
      padding: 0 56px 15px;
      pointer-events: none;
      position: static;
      z-index: 1;

      &.open {
        opacity: 1;
        pointer-events: auto;

        .button--dark {
          opacity: 1;
          pointer-events: auto !important;
        }
      }

      @media only screen and (max-height: 500px) {
        display: flex;
        flex-wrap: wrap;

        .button--dark {
          @media only screen and (max-height: 500px) {
            margin-top: 10px !important;
          }
        }
      }
    }

    a {
      @extend h4;
      color: $dark-gray;
      display: inline-block;
      font-size: 14px;
      text-decoration: none;

      @include respond-to(collapseMenu) {
        font-family: $font-family;
        font-size: 20px;
        font-weight: $opensans-light;
        letter-spacing: -0.01em;
        margin: 0 -56px;
        overflow: hidden;
        padding: 0 56px;
        text-transform: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        width: calc(100% + 94px);
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;

        &:active {
          color: $blue;
        }
      }
    }

    .button--dark {
      color: white;
      // opacity: 0;
      // pointer-events: none;
      transition: background-color 0.25s, border-color 0.25s, opacity 0.5s;

      @include respond-to(collapseMenu) {
        display: block;
        margin: 0 -47px;
        padding: 20px 47px;

        &:active {
          background-color: #00CFFF;
          border-color: #00CFFF;
          color: white;
        }
      }

      &.is-highlighted {
        background-color: white;
        border-color: $light-gray;
        color: $dark-gray;
      }
    }

    .header__active-state {
      animation-timing-function: $easing;
      background: $blue;
      bottom: 0;
      height: 2px;
      opacity: 0;
      position: absolute;
      transform-origin: 0;
      transform: scaleX(.001);
      width: 100px;

      &.animate {
        opacity: 1;
        transition: transform .2s;
      }

      @include respond-to(collapseMenu) {
        display: none;
      }
    }
  }

  .header__link {
    display: inline-block;
    height: $header-height;
    line-height: $header-height;
    margin-right: 30px;

    @include respond-to(collapseMenu) {
      display: block;
      margin: 0 auto;

      @media only screen and (max-height: 500px) {
        height: 50px;
        line-height: 50px;
        margin: 0;
        width: 50%;
      }
    }

    &--active a {
      color: $blue;
    }
  }

  &.header--scrolled {
    .header__sections {
      // transform: translateX(0);
      .button {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .header__buyButton {
    display: inline-block;

    .button--dark.is-highlighted {
      color: $blue;

      @include respond-to(collapseMenu) {
        background-color: $blue;
        border-color: $blue;
        color: white;

        &:hover {
          background-color: #00CFFF;
          border-color: #00CFFF;
        }
      }
    }

    @include respond-to(collapseMenu) {
      display: block;
      width: 100%;
    }
  }
}

.header__openOverlay {
  @include respond-to(collapseMenu) {
    @extend .lightbox--overlay;
    transition: opacity $transition-med;
    z-index: 9;
  }
}

.header__buyOptions {
  display: none;

  &.is-active {
    display: block;
  }
}

.header__buyOverlay {
  @extend .lightbox--overlay;
  height: 100%;
  transition: opacity $transition-med;
  z-index: 11;
}

.header__buyLightbox {
  @include center(xy);
  border: 1px solid rgba(0,0,0,.19);
  box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
  color: $dark-gray;
  height: 90vh;
  min-height: 650px;
  min-width: 1000px;
  opacity: 0;
  position: fixed;
  transition: opacity $transition-med;
  width: 90vw;
  z-index: 12;

  &.is-visible {
    opacity: 1;
  }

  .hero {
    height: 100%;
  }

  h2 {
    color: $dark-gray;
    text-align: center;
    text-transform: none;
  }

  img {
    display: block;
    max-height: 730px;
    margin: 6% auto 0;
    max-width: 100%;
    width: auto;
  }

  .hero__footnote {
    @extend %normalType;
    @include center(x);
    bottom: 85px;
    color: $dark-gray;
    font-size: 13px;
    width: 100%;
  }

  .hero--split .hero__side {
    background-color: $lightest-gray;
    text-align: center;
    transition: background-color $transition-med;
    position: relative;

    &:hover {
      background-color: white;
    }

    .button {
      @extend .button--light;
      @include center(x);
      bottom: 30px;
      padding: 10px 40px;
    }
  }

  .hero__content {
    @include center(xy);
    height: 600px;
    width: 100%;
  }

  a {
    img {
      max-height: 350px;
    }
  }

  .hero__close {
    @extend %close-button;

    position: absolute;
    right: 23px;
    top: 18px;
  }

  @include respond-to(tablet) {
    min-height: auto;
    min-height: initial;
    min-width: auto;
    min-width: initial;

    a {
      display: block;
      height: 50%;
      width: 100%;

      img {
        max-height: 65%;
      }
    }

    .button {
      display: none;
    }

    .hero__footnote {
      display: none;
      transform: translate(-50%, 15px);
      max-width: 240px;
    }

    .hero--split .hero__side {
      height: 100%;
      width: 100%;
    }

    .hero__content {
      position: static;
      height: 100%;
      width: 100%;
      transform: none;
    }
  }

  @include respond-to(smartphone) {
    .hero--split .hero__side {
      padding: 5vw;
    }

    h2 {
      bottom: 15px;
      bottom: 3.25vh;
      font-size: 22px;
      left: 0;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    a {
      img {
        @include center(xy);
        margin: 0 auto;
        max-height: 100%;
        padding: 20px 30px 40px;
      }

      &:nth-child(2) {
        img {
          max-height: 80%;
        }
      }
    }

    .button {
      display: none;
    }
  }
}
