%svg-common {
	background: url(../images/svg/sprite.css-58686fad.svg) no-repeat;
}

.svg-app-search-blue {
	@extend %svg-common;
	background-position: 69.24829157175398% 45.19774011299435%;
}

.svg-app-search-blue-dims {
	width: 50px;
	height: 50px;
}

.svg-apps-android-logo {
	@extend %svg-common;
	background-position: 99.9666035845486% 78.68580894533407%;
}

.svg-apps-android-logo-dims {
	width: 29.7px;
	height: 33.6px;
}

.svg-apps-apple-logo {
	@extend %svg-common;
	background-position: 99.89987762821227% 83.30113165884626%;
}

.svg-apps-apple-logo-dims {
	width: 29.1px;
	height: 33.4px;
}

.svg-apps-chrome-logo {
	@extend %svg-common;
	background-position: 0 99.87574209581666%;
}

.svg-apps-chrome-logo-dims {
	width: 33.7px;
	height: 33.7px;
}

.svg-audio-inputs-35-blue {
	@extend %svg-common;
	background-position: 69.5754716981132% 83.09734513274336%;
}

.svg-audio-inputs-35-blue-dims {
	width: 80px;
	height: 80px;
}

.svg-audio-inputs-35-grey {
	@extend %svg-common;
	background-position: 30.42452830188679% 23.59882005899705%;
}

.svg-audio-inputs-35-grey-dims {
	width: 80px;
	height: 80px;
}

.svg-audio-inputs-OPTICAL-blue {
	@extend %svg-common;
	background-position: 20.99056603773585% 23.59882005899705%;
}

.svg-audio-inputs-OPTICAL-blue-dims {
	width: 80px;
	height: 80px;
}

.svg-audio-inputs-OPTICAL-grey {
	@extend %svg-common;
	background-position: 49.29245283018868% 23.59882005899705%;
}

.svg-audio-inputs-OPTICAL-grey-dims {
	width: 80px;
	height: 80px;
}

.svg-audio-inputs-RCA-blue {
	@extend %svg-common;
	background-position: 96.4622641509434% 84.070796460177%;
}

.svg-audio-inputs-RCA-blue-dims {
	width: 80px;
	height: 80px;
}

.svg-audio-inputs-RCA-grey {
	@extend %svg-common;
	background-position: 39.85849056603774% 23.59882005899705%;
}

.svg-audio-inputs-RCA-grey-dims {
	width: 80px;
	height: 80px;
}

.svg-audio-smarter-cast-any-blue {
	@extend %svg-common;
	background-position: 64.84375% 26.755852842809364%;
}

.svg-audio-smarter-cast-any-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-audio-smarter-cast-any-white {
	@extend %svg-common;
	background-position: 0 67.45819397993311%;
}

.svg-audio-smarter-cast-any-white-dims {
	width: 160px;
	height: 160px;
}

.svg-audio-smarter-enjoy-blue {
	@extend %svg-common;
	background-position: 20.833333333333332% 67.45819397993311%;
}

.svg-audio-smarter-enjoy-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-audio-smarter-enjoy-white {
	@extend %svg-common;
	background-position: 41.666666666666664% 67.45819397993311%;
}

.svg-audio-smarter-enjoy-white-dims {
	width: 160px;
	height: 160px;
}

.svg-audio-smarter-higher-blue {
	@extend %svg-common;
	background-position: 62.5% 67.45819397993311%;
}

.svg-audio-smarter-higher-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-audio-smarter-higher-white {
	@extend %svg-common;
	background-position: 85.67708333333333% 0;
}

.svg-audio-smarter-higher-white-dims {
	width: 160px;
	height: 160px;
}

.svg-chromecast-app-applestore {
	@extend %svg-common;
	background-position: 80.44009779951101% 67.79661016949153%;
}

.svg-chromecast-app-applestore-dims {
	width: 110px;
	height: 50px;
}

.svg-chromecast-app-arrow-grey {
	@extend %svg-common;
	background-position: 55.29953917050691% 93.61031518624642%;
}

.svg-chromecast-app-arrow-grey-dims {
	width: 60px;
	height: 60px;
}

.svg-chromecast-app-getapps-blue {
	@extend %svg-common;
	background-position: 68.3371298405467% 92.28813559322033%;
}

.svg-chromecast-app-getapps-blue-dims {
	width: 50px;
	height: 50px;
}

.svg-chromecast-app-googleplay {
	@extend %svg-common;
	background-position: 100% 73.44632768361582%;
}

.svg-chromecast-app-googleplay-dims {
	width: 110px;
	height: 50px;
}

.svg-chromecast-app-gstore {
	@extend %svg-common;
	background-position: 58.67970660146699% 84.34131736526946%;
}

.svg-chromecast-app-gstore-dims {
	width: 110px;
	height: 90px;
}

.svg-chromecast-app-remote2-blue {
	@extend %svg-common;
	background-position: 74.03189066059225% 92.28813559322033%;
}

.svg-chromecast-app-remote2-blue-dims {
	width: 50px;
	height: 50px;
}

.svg-chromecast-app-search-blue {
	@extend %svg-common;
	background-position: 98.86104783599089% 95.02824858757062%;
}

.svg-chromecast-app-search-blue-dims {
	width: 50px;
	height: 50px;
}

.svg-chromecast-app-whats-on-all2-blue {
	@extend %svg-common;
	background-position: 87.47152619589977% 67.79661016949153%;
}

.svg-chromecast-app-whats-on-all2-blue-dims {
	width: 50px;
	height: 50px;
}

.svg-chromecast-logo {
	@extend %svg-common;
	background-position: 0 5.821583135557727%;
}

.svg-chromecast-logo-dims {
	width: 178px;
	height: 41.7px;
}

.svg-chromecast-logo-grey {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-chromecast-logo-grey-dims {
	width: 178px;
	height: 41.7px;
}

.svg-chromecast-resources-helpcenter {
	@extend %svg-common;
	background-position: 85.42141230068337% 92.28813559322033%;
}

.svg-chromecast-resources-helpcenter-dims {
	width: 50px;
	height: 50px;
}

.svg-chromecast-resources-helpforum {
	@extend %svg-common;
	background-position: 79.72665148063781% 92.28813559322033%;
}

.svg-chromecast-resources-helpforum-dims {
	width: 50px;
	height: 50px;
}

.svg-chromecast-resources-trouble {
	@extend %svg-common;
	background-position: 93.16628701594533% 95.02824858757062%;
}

.svg-chromecast-resources-trouble-dims {
	width: 50px;
	height: 50px;
}

.svg-hdmi {
	@extend %svg-common;
	background-position: 94.69784672377865% 88.41131664853101%;
}

.svg-hdmi-dims {
	width: 64.2px;
	height: 22.8px;
}

.svg-learn-tv-steps-enjoy-blue {
	@extend %svg-common;
	background-position: 100% 0;
}

.svg-learn-tv-steps-enjoy-blue-dims {
	width: 110px;
	height: 110px;
}

.svg-learn-tv-steps-enjoy-white {
	@extend %svg-common;
	background-position: 100% 16.97530864197531%;
}

.svg-learn-tv-steps-enjoy-white-dims {
	width: 110px;
	height: 110px;
}

.svg-tv-better-way-get-videos-1 {
	@extend %svg-common;
	background-position: 100% 31.51862464183381%;
}

.svg-tv-better-way-get-videos-1-dims {
	width: 110px;
	height: 60px;
}

.svg-tv-better-way-get-videos-1-blue {
	@extend %svg-common;
	background-position: 100% 40.11461318051576%;
}

.svg-tv-better-way-get-videos-1-blue-dims {
	width: 110px;
	height: 60px;
}

.svg-tv-better-way-get-videos-1-blue-mobile {
	@extend %svg-common;
	background-position: 77.1889400921659% 80.25641025641026%;
}

.svg-tv-better-way-get-videos-1-blue-mobile-dims {
	width: 60px;
	height: 56px;
}

.svg-tv-better-way-get-videos-2 {
	@extend %svg-common;
	background-position: 100% 57.306590257879655%;
}

.svg-tv-better-way-get-videos-2-dims {
	width: 110px;
	height: 60px;
}

.svg-tv-better-way-get-videos-2-blue {
	@extend %svg-common;
	background-position: 100% 65.9025787965616%;
}

.svg-tv-better-way-get-videos-2-blue-dims {
	width: 110px;
	height: 60px;
}

.svg-tv-better-way-get-videos-2-blue-mobile {
	@extend %svg-common;
	background-position: 62.21198156682028% 93.07692307692308%;
}

.svg-tv-better-way-get-videos-2-blue-mobile-dims {
	width: 60px;
	height: 56px;
}

.svg-tv-better-way-get-videos-3 {
	@extend %svg-common;
	background-position: 100% 48.710601719197705%;
}

.svg-tv-better-way-get-videos-3-dims {
	width: 110px;
	height: 60px;
}

.svg-tv-better-way-get-videos-3-blue {
	@extend %svg-common;
	background-position: 60.880195599022% 45.845272206303726%;
}

.svg-tv-better-way-get-videos-3-blue-dims {
	width: 110px;
	height: 60px;
}

.svg-tv-better-way-get-videos-3-blue-mobile {
	@extend %svg-common;
	background-position: 84.10138248847926% 80.25641025641026%;
}

.svg-tv-better-way-get-videos-3-blue-mobile-dims {
	width: 60px;
	height: 56px;
}

.svg-tv-smarter-discover-new-blue {
	@extend %svg-common;
	background-position: 85.67708333333333% 26.755852842809364%;
}

.svg-tv-smarter-discover-new-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-discover-new-white {
	@extend %svg-common;
	background-position: 64.84375% 0;
}

.svg-tv-smarter-discover-new-white-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-game-on-blue {
	@extend %svg-common;
	background-position: 41.666666666666664% 40.702341137123746%;
}

.svg-tv-smarter-game-on-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-game-on-white {
	@extend %svg-common;
	background-position: 20.833333333333332% 40.702341137123746%;
}

.svg-tv-smarter-game-on-white-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-mirror-android-blue {
	@extend %svg-common;
	background-position: 0 40.702341137123746%;
}

.svg-tv-smarter-mirror-android-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-mirror-android-white {
	@extend %svg-common;
	background-position: 44.010416666666664% 0;
}

.svg-tv-smarter-mirror-android-white-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-mirror-website-blue {
	@extend %svg-common;
	background-position: 23.177083333333332% 0;
}

.svg-tv-smarter-mirror-website-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-mirror-website-white {
	@extend %svg-common;
	background-position: 41.666666666666664% 94.21404682274247%;
}

.svg-tv-smarter-mirror-website-white-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-stream-fast-blue {
	@extend %svg-common;
	background-position: 0 13.946488294314381%;
}

.svg-tv-smarter-stream-fast-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-stream-fast-white {
	@extend %svg-common;
	background-position: 20.833333333333332% 94.21404682274247%;
}

.svg-tv-smarter-stream-fast-white-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-turn-screen-blue {
	@extend %svg-common;
	background-position: 85.67708333333333% 53.51170568561873%;
}

.svg-tv-smarter-turn-screen-blue-dims {
	width: 160px;
	height: 160px;
}

.svg-tv-smarter-turn-screen-white {
	@extend %svg-common;
	background-position: 0 94.21404682274247%;
}

.svg-tv-smarter-turn-screen-white-dims {
	width: 160px;
	height: 160px;
}

