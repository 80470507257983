.buy__hero {
  height: 500px;

  @include respond-to(smartphone) {
    background-color: $lightest-gray;
    background-repeat: no-repeat;
    height: 300px;
    min-height: auto;
    min-height: initial;
  }
}

.buy__header {
  min-height: auto;
  min-height: initial;
  padding-top: 100px;

  .section__content {
    width: auto;
  }
  @include respond-to(smartphone) {
    text-align: center;
  }
}

.buy__retailers {
  min-height: auto;
  min-height: initial;
  overflow: hidden;
  padding: 20px 0 40px;

  a[href=""] {
    cursor: default;
    //-webkit-filter: grayscale(1);
    opacity: 0.7;

    .card--retailer:hover {
      background-color: $lightest-gray;
      box-shadow: none;
    }
  }

  .cards {
    margin: 0 auto;
    max-width: 1600px;
    text-align: left;
  }

  .card--retailer {
    margin: 0.332%;
    width: 32.57%;
  }

  @include respond-to(smartphone) {
    .card {
      display: block;
      height: 50vw;
      margin: 15px;
      width: auto;
    }
  }

  .card__image {
    @include center(xy);
    max-height: 100%;
    max-width: 100%;
  }
}

.buy__specs {
  min-height: auto;
  min-height: initial;

  .section__inner {
    align-items: flex-start;
    transition: max-height $transition-med $easing;
  }

  .section__content {
    margin-top: 50px;
  }

  img {
    height: 872px;
    width: 800px;
  }

  .section__imageContainer {
    flex-grow: 1;
    text-align: center;
  }

  @include respond-to(tablet) {
    .section__content {
      width: 40%;
    }
  }

  @include respond-to(smartphone) {
    h2 {
      text-align: center;
    }

    .section__imageContainer {
      display: none;
    }

    .section__inner.section__inner--left {
      margin-left: 0;
    }

    .section__content {
      margin-top: 0;
      width: 100%;
    }
  }
}

.specs {
  @extend %normalType;
  font-size: 13px;
  margin-top: 50px;

  ul {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;

    li {
      margin: 10px 0;
      position: relative;

      &:before {
        content: '•';
        position: absolute;
        left: -15px;
      }
    }
  }

  .specs__imageContainer {
    display: none;
  }

  .specs__mainSpecName, .specs__toggle {
    @extend %smallCaps;
    color: $blue;
    font-size: 14px;
    margin-top: 40px;
  }

  .specs__collapsedSpecs {
    margin-top: 10px;
    transition: opacity $transition-sm;

    &.is-hidden {
      opacity: 0;
    }
  }

  .specs__collapsedSpec {
    margin: 10px 0;
  }

  .specs__collapsedSpecItem--single, .specs__collapsedSpecName {
    display: inline-block;
  }

  .specs__collapsedSpecName {
    @extend %boldType;
  }

  .specs__collapsedSpecItem {
    margin: 5px 0;
  }

  .specs__collapsedSpecColor {
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 10px;
    width: 10px;
  }

  .spec__collapsedSpec--color > .specs__collapsedSpecItem {
    display: inline-block;
  }

  .specs__toggle {
    cursor: pointer;
  }

  .specs__toggleArrow {
    display: inline-block;
    transform-origin: 0 -1px;
    transition: transform $transition-sm ease-out;

    &:after, &:before {
      background: $blue;
      content: '';
      display: block;
      height: 2px;
      width: 8px;
    }

    &:after {
      transform: translateY(-4px) translateX(5px) rotate(-45deg);
    }

    &:before {
      transform: translateY(-2px) rotate(45deg);
    }
  }

  @include respond-to(smartphone) {
    .specs__imageContainer {
      display: block;

      img {
        height: auto;
        width: 100%;
      }
    }

    li:before {
      display: none;
    }
  }
}