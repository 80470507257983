html.noScroll {
  height: 101%; // 101% to ensure scrollbar is present for Chrome OS to avoid width change flicker

  body {
    @include respond-to(desktop) {
      overflow: hidden;
    }
    @include respond-to(tablet) {
      overflow: hidden;
    }
    @include respond-to(smartphone) {
      position: fixed;
      overflow: visible;
    }
  }
}

html {
  box-sizing: border-box;

  &.wf-active,
  &.wf-inactive {
    body {
      opacity: 1;
    }
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  backface-visibility: hidden;
  font-family: $font-family;
  font-smoothing: antialiased;
  opacity: 0;
  transition: opacity .3s;
  -webkit-font-smoothing: antialiased;
}

body.noScroll {
  position: fixed;
}

@-ms-viewport { width: device-width; } // Use transparent scrollbars in IE

main {
  padding-top: $header-height;
  position: relative;
  transition: transform 0.5s;
  z-index: 4;
}

footer {
  position: relative;
  z-index: 3;

  @include respond-to(tablet) {
    z-index: 2;
  }
  @include respond-to(smartphone) {
    z-index: 1;
  }
}

img {
  opacity: 1;
  transition: opacity .3s;

  &.is-hidden {
    opacity: 0;
  }
}

.lightbox--overlay {
  background: rgba(255,255,255,0.93);
  left: 0;
  height: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.5s;
  transition-delay: 0.4s;
  z-index: 11;
  width: 100%;

  &.is-active {
    opacity: 1;
  }
}

.slick-slider {
  .slick-prev,
  .slick-next {
    background: transparent;
    border: none;
    font-size: 0;
    height: 20px;
    outline: none;
    padding: 25px 35px;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0) scaleX(.7);
    width: 40px;

    html.touch & {
      display: none !important;
    }
    
    @include respond-to(smartphone) {
      display: none !important;
    }

    &:hover {
      &:after {
        border-color: #fff;
      }
    }

    &:after {
      content: '';
      height: 20px;
      left: 30px;
      position: absolute;
      top: 15px;
      transform: rotate(45deg);
      transition: border-color 0.3s;
      width: 20px;
    }
  }

  .slick-prev {
    left: 10px;
    z-index: 3;

    &:after {
      border-bottom: solid 3px $dark-gray;
      border-left: solid 3px $dark-gray;
    }
  }

  .slick-next {
    height: 100%;
    right: 0;
    transform-origin: 100% 0;
    width: 143%;
    z-index: 2;

    &:after {
      border-top: solid 3px $dark-gray;
      border-right: solid 3px $dark-gray;
      left: auto;
      right: 60px;
      top: 50%;
      transform: translate3d(0, -50%, 0) rotate(45deg);
    }
  }

  .slick-dots {
    bottom: 36px;
    left: 36px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 2;

    @include respond-to(smartphone) {
      bottom: 10px;
      left: 10px;
    }

    li {
      float: left;

      &.slick-active {
        button:before {
          background: #fff;
        }
      }
    }

    button {
      background: transparent;
      border: none;
      display: block;
      font-size: 0;
      height: 12px;
      outline: none;
      position: relative;
      width: 12px;

      &:before {
        background: transparent;
        border: solid 1px #fff;
        border-radius: 50%;
        content: '';
        display: block;
        height: 6px;
        left: 3px;
        position: absolute;
        top: 3px;
        transition: background 0.3s;
        width: 6px;
      }
    }
  }
}

%close-button {
  cursor: pointer;
  height: 30px;
  width: 30px;

  &:hover {
    &:after, &:before {
      background-color: $blue;
    }
  }

  &:after, &:before {
    background-color: $dark-gray;
    content: '';
    display: block;
    position: relative;
    top: 13px;
    transition: background-color .3s;
    height: 2px;
    width: 30px;
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:before {
    transform: translateY(2px) rotate(45deg);
  }
}

%arrow-button {
  background: $lighter-gray;
  border-radius: 40px;
  box-shadow: 0px 2px 10px rgba(0,0,0,.3),0px 0px 1px rgba(0,0,0,.1),inset 0px 1px 0px rgba(255,255,255,.25),inset 0px -1px 0px rgba(0,0,0,.15);
  cursor: pointer;
  height: 50px;
  opacity: 1;
  transition: background $transition-sm, transform $transition-sm, opacity $transition-sm, box-shadow $transition-sm;
  width: 50px;
  z-index: 1;

  &:hover {
    background: #DCDDDE;
    box-shadow: 0px 5px 5px rgba(0,0,0,.2),0px 10px 14px rgba(0,0,0,.1),inset 0px 1px 0px rgba(255,255,255,.25),inset 0px -1px 0px rgba(0,0,0,.10);
  }

  &:before, &:after {
    background: #424257;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    top: 25px;
    width: 8px;
  }

  &:before {
    transform: rotate(45deg);
    left: 19px;
  }

  &:after {
    transform: rotate(-45deg);
    left: 24px;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }

}

%numbered-list {
  counter-reset: ol-counter;
  padding: 10px 0 0;
  margin: 25px 0 0;
  position: relative;

  &:before {
    background: $blue;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 60px;
  }

  li {
    clear: left;
    float: left;
    font-size: 13px;
    list-style-type: none;
    margin: 20px 0 0 35px;

    @include respond-to(smartphone) {
      font-size: 11px;
      position: relative;
    }

    &:before {
      border: solid 1px $blue;
      border-radius: 50%;
      content: counter(ol-counter);
      counter-increment: ol-counter;
      color: $blue;
      display: inline-block;
      font-size: 10px;
      margin: -4px 10px 0 -33px;
      padding: 1px 0 2px;
      text-align: center;
      width: 20px;
      vertical-align: middle;

      @include respond-to(smartphone) {
        position: absolute;
        top: 3px;
      }
    }
  }
}

.fadein__img {
  display: none;
}
