.cards {
  text-align: center;

  a {
    -webkit-tap-highlight-color: none;
  }

  &[data-columns = "2"] .card {
    width: 48vw;

    @include respond-to(smartphone) {
      width: 90vw;
    }
  }

  &[data-columns = "6"] .card {
    width: 15vw;

    @include respond-to(smartphone) {
      width: auto;
    }
  }
}

.card {
  animation-timing-function: $easing;
  backface-visibility: hidden;
  background-color: white;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $dark-gray;
  display: inline-block;
  height: 340px;
  margin: 0.332vw;
  padding: 20px;
  position: relative;
  text-align: left; // ie fallback
  text-align: start;
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.5s;
  width: 32vw;
  vertical-align: top;

  @include respond-to(tablet) {
    width: 48vw;
  }
  @include respond-to(smartphone) {
    margin: 1vw 5vw;
    width: 90vw;
  }

  &:hover,
  &:active {
    box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
  }

  h2 {
    font-size: 35px;
    font-weight: $opensans-light;
    margin-bottom: 50px;
    margin-top: 0;

    @include respond-to(smartphone) {
      font-size: 30px;
      margin-bottom: 5px;
    }
  }

  p {
    font-size: 18px;
    position: relative;
    max-width: 300px;

    @include respond-to(smartphone) {
      font-size: 14px;
    }

    &:before {
      border-top: 1px solid $lighter-gray;
      content: '';
      position: absolute;
      top: -25px;
      width: 150px;

      @include respond-to(smartphone) {
        display: none;
      }
    }
  }

  .button {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}

.card--standard {
  @extend .card;
  padding: 0;

  .card__image {
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
    width: 100%;

    @media only screen and (min-width: 1600px) {
      background-position: bottom right;
      background-size: auto 100%;
    }
  }

  .card__content {
    height: 100%;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:hover {
    z-index: 1;
  }
}

.cards > a .card--standard{
  &:hover {
    .button--light {
      transition: background-color 0.25s, color 0.25s;
      @extend .button;
    }
  }
}

.card--expandable {
  @extend .card;
  cursor: pointer;
  overflow: hidden;
  padding: 0;
  position: relative;

  @include respond-to(tablet) {
    width: 32vw;
  }

  &.is-active {
    &:after{
      background-color: rgba(255, 255, 255, .8);
    }

    .card__icon--blue {
      opacity: 1;
    }

    &:hover .card__expand, .card__icon--white, .card__title, .card__animation {
      opacity: 0;
    }

    &:hover {
      box-shadow: none;
    }

    &:hover .card__image--full {
      opacity: 0;

      @include respond-to(smartphone-large) {
        opacity: 1;
      }
    }
  }

  @include respond-to(smartphone-large) {
    cursor: auto;
    height: auto;
    margin: 1vw auto;
    max-height: 75px;
    transition: max-height $transition-med $easing;
    width: 90vw;

    &.is-active {
      max-height: 1000px;

      &:after {
        background-color: transparent;
      }

      .card__icon--blue {
        opacity: 0;
      }

      &:hover .card__expand, .card__icon--white, .card__title {
        opacity: 1;
      }

      .card__expand {
        transform: translateX(-5px) rotateX(180deg);
      }

      .card__content {
        background: linear-gradient(to bottom, rgba(0,0,0,0.3), transparent);      cursor: pointer;
        z-index: 1;
      }

      .card__image {
        pointer-events: auto;
      }

      .card__mobileExpandedDetails {
        opacity: 1;
        position: relative;
        z-index: 1;
      }
    }
  }

  .card__image {
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;

    &.card__image--full {
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 1.15s;
      z-index: 1;
    }

    @include respond-to(smartphone-large) {
      height: 55vw;
      pointer-events: none;

      &.card__image--full {
        opacity: 1;

        &.is-hidden {
          opacity: 0;
        }
      }
    }
  }

  .card__content {
    @include center(xy);
    font-size: 14px;
    padding: 20px;
    text-align: center;
    z-index: 2;

    @include respond-to(smartphone-large) {
      height: 100%;
      left: 0;
      padding: 0 10px;
      text-align: left;
      text-align: start;
      top: 0;
      transform: none;
      transition: background $transition-med;
      white-space: nowrap;
      width: 100%;
    }
  }

  .card__icon {
    margin: 0 auto 20px;
    opacity: 0.8;

    @include respond-to(smartphone-large) {
      display: inline-block;
      margin: 2px 0 0 0;
      transform: scale(0.5);
      transform-origin: 0 0;
    }

    &.is-hidden {
      opacity: 0;
      transition: none;
    }
  }

  .card__icon--white {
    height: 150px;
    overflow: hidden;
    width: 150px;
  }

  .card__iconInner {
    background-size: cover;
    height: 150px;
    transform: translateX(-1350px);
    transition: transform 0.3s steps(9);
    width: 1500px;

    &.card__icon--long {
      transform: translateX(-1500px);
      transition: transform 0.3s steps(10);
      width: 1650px;
    }

    &.is-hovered {
      transform: translate(0);
    }
  }

  .card__icon--blue {
    @include center(x);
    opacity: 0;
    top: 20px;

    @include respond-to(smartphone-large) {
      left: 10px;
      top: 55px;
      transform: translateY(-50%) scale(0.5);
      transform-origin: 0 0;
    }
  }

  .card__animation {
    @include center(x);
    display: none;
    height: 150px;
    width: 150px;
    top: 25px;

    @include respond-to(smartphone-large) {
      display: none;
    }

    &.is-active {
      display: block;
    }
  }

  &:hover {
    z-index: 1;

    .card__image--full {
      opacity: 1;
    }
  }

  .card__title {
    @extend %smallCaps;
    color: white;
    margin-top: -40px;
    opacity: 1;
    transition: opacity $transition-med;

    @include respond-to(smartphone-large) {
      display: inline-block;
      font-size: 10px;
      left: 80px;
      margin-top: 0;
      max-width: 65%;
      overflow: hidden;
      position: absolute;
      // text-overflow: ellipsis;
      top: 30px;
    }

    @media only screen and (max-width: 350px) {
      left: 75px;
      max-width: 62%;
    }
  }

  .card__expand {
    @extend %smallCaps;
    @include center(x);
    bottom: 40px;
    color: white;
    font-size: 14px;
    opacity: 0;
    transition: opacity $transition-med;
    z-index: 2;

    &:after {
      content: '›';
      margin-left: 5px;
    }

    @include respond-to(smartphone-large) {
      font-size: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid white;
      height: 0;
      left: auto;
      opacity: 1;
      position: absolute;
      right: 20px;
      top: 30px;
      transition: transform $transition-sm;
      width: 0;

      &:after {
        content: '';
      }
    }
  }

  &:hover .card__expand {
    opacity: 1;
  }

  &:after {
    background: rgba(0, 0, 0, .28);
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    transition: background-color $transition-med;
    top: 0;
    z-index: 1;
  }
}

.card__mobileExpandedDetails {
  opacity: 0;
  padding: 20px;
  transition: opacity $transition-sm;

  p:before {
    display: none;
  }

  p {
    font-weight: $opensans-regular;
    font-size: 13px;
    line-height: 1.5em;
    max-width: none;
  }

  .button {
    @extend .button--light;
    display: inline-block;
    margin: 15px 0 0;
    position: static;
  }
}

.cards__expandedCards {
  height: 680px;
  margin: 0.332vw 0;
  position: relative;

  @include respond-to(smartphone-large) {
    display: none;
  }

  &.ng-hide-add.ng-hide-add-active,
  &.ng-hide-remove.ng-hide-remove-active {
    transition: height $transition-med $easing;
  }

  &.ng-hide {
    height: 0;
  }

  .card__closeButton {
    @extend %close-button;
    position: absolute;
    right: 26px;
    top: 12px;
    z-index: 1;

    &.ng-hide-add.ng-hide-add-active,
    &.ng-hide-remove.ng-hide-remove-active {
      transition: opacity $transition-sm;
    }

    &.ng-hide {
      opacity: 0;
    }
  }

  .cards__arrowPrev, .cards__arrowNext {
    @extend %arrow-button;
    @include center(y);

    &.ng-hide-add.ng-hide-add-active,
    &.ng-hide-remove.ng-hide-remove-active {
      transition: opacity $transition-sm;
    }

    &.ng-hide {
      opacity: 0;
    }

    &:before, &:after {
      top: 27px;
    }
  }

  .cards__arrowPrev {
    left: 5px;

    &:before {
      transform: rotate(45deg);
      left: 20px;
    }

    &:after {
      transform: rotate(-45deg) translateY(-7px);
      left: 25px;
    }
 }

  .cards__arrowNext {
    right: 5px;
    &:before {
      transform: rotate(45deg) translateY(-7px);
      left: 17px;
    }

    &:after {
      transform: rotate(-45deg);
      left: 22px;
    }
  }
}

.card--expanded {
  @include center(x);
  background: white;
  box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
  height: 680px;
  opacity: 1;
  text-align: left;
  top: 0;
  width: 97.328vw;

  &.ng-hide-add.ng-hide-add-active,
  &.ng-hide-remove.ng-hide-remove-active {
    transition: opacity $transition-med;
  }

  &.ng-hide {
    opacity: 0;
  }

  .card__image {
    background-size: cover;
    background-position: center center;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 67%;
  }

  .card__content {
    display: inline-block;
    position: relative;
    height: 100%;
    vertical-align: top;
    width: 33%;

    h2 {
      margin-bottom: 20px;
    }

    .button {
      @extend .button--light;
      display: inline-block;
      margin-top: 30px;
    }
  }

  .card__contentInner {
    width: 80%;
    @include center(xy);
  }

  .card__breadcrumbs {
    bottom: 125px;
    left: 10%;
    position: absolute;

    @include respond-to(tablet) {
      bottom: 50px;
    }
  }
}

.card__breadcrumb {
  border: 1px solid $light-gray;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin: 0 5px;
  width: 6px;

  &.is-active {
    background: $light-gray;
  }
}

.card--video {
  @include center(x, relative);
  background: white;
  display: flex;
  height: auto;
  justify-content: center;
  margin-bottom: 20px;
  min-height: calc((97.328vw / 2) * (9/16));
  min-height: 27.3735vw;
  opacity: 1;
  overflow: auto;
  text-align: left;
  top: 0;
  transition: box-shadow 0.3s, max-height 0.3s;
  width: 97.328vw;

  @include respond-to(smartphone-large) {
    display: block;
    max-height: 75px;
    min-height: 75px !important;
    overflow: hidden;
    width: 90vw;
  }

  &:last-child {
    @include respond-to(smartphone-large) {
      margin-bottom: 0;
    }
  }

  &.is-active {
    @include respond-to(smartphone-large) {
      max-height: 1000px;
    }

    .card__mobileHeader {
      @include respond-to(smartphone-large) {
        opacity: 0;
      }
    }

    .youtubePlayer,
    iframe,
    .card__slide {
      opacity: 1;
    }
  }

  &:hover,
  &:active {
    box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
  }

  .card__mobileHeader {
    background-color: rgba(0,0,0,0.4);
    background-blend-mode: multiply;
    background-position: center center;
    background-size: cover;
    display: none;
    height: 75px;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: opacity .3s;
    width: 100%;
    z-index: 5;

    @include respond-to(smartphone-large) {
      display: block;
    }

    h4 {
      align-items: center;
      color: #fff;
      display: flex;
      font-size: 12px;
      height: 75px;
      margin: 0 45px 0 25px;

      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid white;
        content: '';
        left: auto;
        opacity: 1;
        position: absolute;
        right: 20px;
        top: 34px;
      }
    }
  }

  .card__video,
  .card__slides {
    float: left;
    overflow: hidden;
    position: relative;
    width: 50%;

    @include respond-to(smartphone-large) {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .card__content {
    align-items: center;
    display: flex;
    float: left;
    padding: 30px 5% 45px 5%;
    transform: translateZ(0);
    width: 50%;

    @include respond-to(smartphone-large) {
      margin-top: calc(90vw * (9/16));
      padding: 30px 5%;
      width: 100%;
    }

    .card__contentCenter {
      max-width: 100%;
    }
  }

  .youtubePlayer,
  iframe {
    float: left;
    height: calc(97.328vw / 2 * (9/16));
    height: 100%;
    position: absolute;

    @include respond-to(smartphone-large) {
      height: calc(90vw * (9/16));
      opacity: 0;
      position: relative;
      transition: opacity .3s;
    }
  }

  .card__slide {
    background-position: center center;
    background-size: cover;
    float: left;
    height: calc(97.328vw / 2 * (9/16));
    width: 100%;

    @include respond-to(smartphone-large) {
      height: calc(90vw * (9/16));
      opacity: 0;
      transition: opacity .3s;
    }

    &:not(:first-child):not(.slick-slide) {
      left: 0;
      position: absolute;
      top: 0;
    }

    img {
      float: left;
      max-width: 100%;
      max-height: 100%;
    }
  }

  h3 {
    color: #000;
    margin-bottom: .6em;
  }

  ol {
    @extend %numbered-list;
  }

  .button {
    clear: left;
    float: left;
    margin-top: 20px;
  }
}

.card--slides {
  @extend .card--video;

  ol {
    li {
      cursor: pointer;
      transition: color .3s;

      &:hover {
        color: $dark-gray;
      }

      &:before {
        transition: color .3s, border-color .3s;
      }

      &.is-active {
        color: $blue;

        &:before {
          border-color: $blue;
          color: $blue;
        }
      }
    }
  }
}

.card--retailer {
  @extend .card;
  background-color: $lightest-gray;
  height: 250px;
  transition: background-color $transition-med, box-shadow $transition-med;

  .card__footnote {
    @extend %normalType;
    @include center(x);
    bottom: 15px;
    font-size: 13px;
    text-align: center;
    width: 90%;
  }

  &:hover {
    background-color: white;
  }
}

.card__chromecastApp {
  margin: 20px 0;
  position: relative;

  .card__chromecastAppAppStore {
    @extend .svg-chromecast-app-applestore;
    @extend .svg-chromecast-app-applestore-dims;
    float: left;
    margin: -2px 0 0 -5px;
  }

  .card__chromecastAppPlayStore {
    @extend .svg-chromecast-app-googleplay;
    @extend .svg-chromecast-app-googleplay-dims;
    float: left;
    margin: -2px 0 0;
  }

  .card__chromecastAppComingSoon {
    align-items: center;
    background: $blue;
    border-radius: 50%;
    box-shadow: 0 17px 50px 0 rgba(0,0,0,0.19);
    color: #fff;
    display: flex;
    font-size: 20px;
    height: 170px;
    left: 50%;
    padding: 0 20px;
    position: absolute;
    text-align: center;
    top: 50px;
    transform: translate3d(-46vw, -420px, 0);
    width: 170px;

    @include for-langs(en_us) {
      display: none;
    }

    @include respond-to(smartphone) {
      font-size: 16px;
      height: 130px;
      transform: translate3d(-46vw, -520px, 0);
      width: 130px;
    }

    span {
      display: inline-block;
      max-width: 130px;

      @include respond-to(smartphone) {
        max-width: 90px;
      }
    }
  }
}
