.apps {
  opacity: 1;
  transition: opacity .3s;

  &.is-hidden {
    opacity: 0;
  }
}

.apps__hero {
  .hero__arrow {
    display: none;
  }
}

.hero--carousel {
  @extend .hero;

  h1, p {
    position: relative;
  }

  .carousel {
    background: #000;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .carousel__slide {
    background: $darkest-gray;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s;
    width: 100%;

    @include respond-to(smartphone) {
      &:first-child {
        opacity: 1;
      }
      
      &:not(:first-child) {
        display: none;
      }
    }

    &.is-active {
      opacity: 1;
    }

    &:before {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
      bottom: 0;
      content: '';
      height: 100px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .carousel__slideBg {
      background: #000;
      background-blend-mode: multiply !important;
      background-color: rgba(0,0,0,0.25) !important;
      background-position: center center;
      background-size: cover;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .carousel__slideAttribution {
      @extend .attribution;
      top: 16px;
      color: #fff;
      position: absolute;
      right: 20px;
      z-index: 2;

      @include respond-to(smartphone) {
        bottom: 16px;
        top: auto;
      }
    }
  }

  .carousel__pager {
    bottom: -73px;
    height: 212px;
    left: 0;
    position: absolute;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;
    white-space: nowrap;

    @include respond-to(smartphone) {
      display: none;
    }
  }

  .carousel__pagerItem {
    background: $darkest-gray;
    display: inline-block;
    height: 125px;
    margin: 77px 0 0 5px;
    position: relative;
    width: 162px;

    &:nth-child(4n+1) {
      .carousel__pagerItemBackground {
        background: url(../images/apps/apps-thumb-espn.jpg) center center;
      }
    }
    &:nth-child(4n+2) {
      .carousel__pagerItemBackground {
        background: url(../images/apps/apps-thumb-hbo.jpg) center center;
      }
    }
    &:nth-child(4n+3) {
      .carousel__pagerItemBackground {
        background: url(../images/apps/apps-thumb-google-photos.jpg) center center;
      }
    }

    &:hover,
    &.is-active {
      .carousel__pagerItemHoverBackground {
        transform: translate3d(0, -37px, 0);
      }

      .carousel__pagerItemBackground {
        opacity: 0 !important;
      }

      .carousel__pagerItemIconLarge {
        opacity: 1;
        transform: translate3d(0, -22px, 0);
      }

      .carousel__pagerItemIconSmall {
        opacity: 0;
      }

      .carousel__pagerItemLink,
      .carousel__pagerItemTitle {
        opacity: 1;
      }

      html.no-touch & {
        .carousel__pagerItemLink {
          z-index: 5;
        }
      }
    }

    &.is-active {
      html.touch & {
        .carousel__pagerItemLink {
          z-index: 5;
        }
      }
    }

    //&.has-link {
      &:hover,
      &.is-active {
        .carousel__pagerItemHoverBackground {
          transform: translate3d(0, -77px, 0);
        }

        .carousel__pagerItemIconLarge {
          transform: translate3d(0, -65px, 0);
        }
      }

      .carousel__pagerItemLink,
      .carousel__pagerItemTitle {
        display: flex;
      }
    //}
  }

  .carousel__pagerItemBackground {
    background: url(../images/apps/apps-thumb-google-play-movies.jpg) center center;
    background-size: cover !important;
    content: '';
    left: 0;
    height: 100%;
    opacity: 1;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: opacity .3s;
    width: 100%;
    z-index: 3;
  }

  .carousel__pagerItemHoverBackground {
    background: $darkest-gray;
    content: '';
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: transform .3s;
    width: 100%;
  }

  .carousel__pagerItemIconLarge {
    left: 25px;
    height: 112px;
    opacity: 0;
    top: 10px;
    transform: translate3d(0, 0, 0);
    transition: opacity .3s, transform .3s;
    position: relative;
    width: 112px;
    z-index: 2;

    &.is-hidden {
      opacity: 0;
    }
  }

  .carousel__pagerItemIconSmall {
    bottom: -10px;
    height: 45px;
    opacity: 1;
    position: absolute;
    right: 10px;
    transition: opacity .1s;
    width: 45px;
    z-index: 4;

    &.is-hidden {
      opacity: 0;
    }
  }

  .carousel__pagerItemTitle,
  .carousel__pagerItemLink {
    align-items: center;
    bottom: 0;
    color: #fff;
    display: none;
    font-size: 10px;
    font-weight: 600;
    height: 60px;
    justify-content: center;
    left: 0;
    letter-spacing: 1px;
    line-height: 14px;
    opacity: 0;
    padding: 0 25px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    transition: opacity .3s;
    width: 100%;
    white-space: normal;
  }

  .carousel__pagerItemLink {
    z-index: 2;
  }
}

.section--gallery {
  background: $lightest-gray;
  padding: 0 1vw;

  @include respond-to(smartphone) {
    padding: 0;
  }
}

.section__appsSearch {
  padding: 180px 0 90px;
  text-align: center;

  @include respond-to(tablet) {
    float: left;
    margin-left: 45px;
    padding: 180px 0 0;
  }

  @include respond-to(smartphone) {
    display: none;
  }

  input {
    background: none;
    border: none;
    border-bottom: solid 1px $light-gray;
    color: $dark-gray;
    font-size: 24px;
    font-weight: 300;
    outline: none;
    padding: 5px 35px 5px 10px;
    width: 300px;

    @include respond-to(smartphone) {
      font-size: 15px;
      padding: 5px 30px 5px 0;
      width: 125px;
    }
  }

  .section__appsSearchSubmit {
    @extend .svg-app-search-blue;
    @extend .svg-app-search-blue-dims;
    display: inline-block;
    margin: 0 0 -17px -45px;

    @include respond-to(smartphone) {
      transform: scale(.75);
    }
  }
}

.section__appsFilters {
  @include respond-to(smartphone) {
    overflow: auto;
    padding-bottom: 10px;
    position: relative;
  }

  a {
    color: $dark-gray;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    font-weight: 300;
    padding: 8px 0;
    position: relative;

    &:hover,
    &.is-active {
      color: $blue;

      &:after {
        opacity: 1;
      }
    }

    &:after {
      background: $blue;
      bottom: 0px;
      content: '';
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity .3s;
      width: 100%;
    }
  }
}

.section__appsCategories {
  float: left;
  margin: 0 30px;

  @include respond-to(tablet) {
    margin: 0;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 60px;
    width: 98vw;
  }

  @include respond-to(smartphone) {
    padding-bottom: 0;
    width: 100vw;
  }

  .section__appsCategoriesScroll {
    position: absolute;
    transition: opacity .3s;

    &.is-hidden {
      opacity: 0;
      pointer-events: none;

      @include respond-to(tablet) {
        display: none;
      }
    }

    @include respond-to(tablet) {
      position: static;
      display: block;
      white-space: nowrap;
    }
  }

  a {
    margin: 0 15px;

    @include respond-to(tablet) {
      &:first-child {
        margin-left: 45px;
      }
    }

    @include respond-to(smartphone) {
      display: inline-block;
      margin: 0 30px 0 0;

      &:first-child {
        margin-left: 7vw;
      }
    }
  }
}

.section__appsDevices {
  color: $dark-gray;
  float: right;
  font-size: 17px;
  font-weight: 300;
  margin-right: 45px;
  opacity: 1;
  transition: opacity 0.3s;
  transform: translate3d(0,0,0);

  @include respond-to(tablet) {
    padding: 180px 0 20px;
  }

  @include respond-to(smartphone) {
    float: none;
    height: 39px;
    margin: 37px 7vw 27px 7vw;
    padding: 0;
  }

  &.is-hidden {
    opacity: 0;
  }

  &.is-selectable {
    .section__appsDevicesActive {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    .section__appsDevicesSelector {
      opacity: 1;
      transform: translate3d(0, -100%, 0);
    }
  }

  .section__appsDevicesActive {
    opacity: 1;
    text-align: right;
    transition: opacity .3s, transform .3s;

    @include respond-to(smartphone) {
      text-align: left;
    }

    a {
      display: none;

      &.is-active {
        display: inline-block;
      }
    }
  }

  .section__appsDevicesSelector {
    opacity: 0;
    text-align: center;
    transition: opacity .3s, transform .3s;

    @include respond-to(smartphone) {
      text-align: left;

      a:first-child {
        margin-left: 0;
      }
    }

    a {
      margin: 0 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.section__appsGrid,
.section__categoryGrid {
  clear: both;
  min-height: 300px;
  padding: 30px 0 100px;
  width: 100%;
  width: 98vw;

  @include respond-to(smartphone) {
    overflow: hidden;
    padding: 30px 0 0;
    width: 100vw;
  }

  &.is-hidden {
    display: none;
  }
}

.section__appsGrid {
  line-height: 0;

  @include respond-to(smartphone) {
    margin: 0 calc(7vw - 2px);
    padding-bottom: 60px;
    width: calc(86vw + 4px);
  }
}

.section__appsGridNoResults {
  color: $darker-gray;
  display: block;
  font-size: 17px;
  font-weight: 300;
  margin: 100px 0 20px;
  opacity: 1;
  position: absolute;
  text-align: center;
  transition: opacity .3s;
  width: 98vw;

  @include respond-to(smartphone) {
    width: calc(86vw + 4px);
  }

  &.is-hidden {
    opacity: 0;
  }
}

.section__appsGridMore {
  cursor: pointer;
  display: table;
  line-height: normal;
  margin: 70px auto 0;
  position: relative;
  z-index: 1;

  @include respond-to(smartphone) {
    margin: 60px auto 0;
  }
}

.app {
  background: #fff;
  box-sizing: border-box;
  display: inline-block;
  line-height: initial;
  margin: 0.166vw;
  opacity: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  transform: scale(1) translate3d(0, 0, 0);
  transition: transform .3s, opacity .3s, box-shadow .3s;
  width: 16vw;
  z-index: 1;

  @include respond-to(smartphone) {
    margin: 2px;
    width: calc((86vw - 8px) / 3);
  }

  html.no-touch &:hover,
  html.no-touch &:active {
    box-shadow: 0 17px 50px 0 rgba(0,0,0,0.19);
    transform: scale(1.05) translate3d(0, 0, 0);
    z-index: 2;

    .app__background {
      opacity: 1;

      @include respond-to(smartphone) {
        opacity: 0;
      }
    }

    .app__logo {
      transform: translate3d(0, -30px, 0);

      @include respond-to(smartphone) {
        transform: translate3d(0, 0, 0);
      }
    }

    .app__name,
    .app__description {
      opacity: 0;
      transform: translate3d(0, -70px, 0);

      @include respond-to(smartphone) {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    .app__nameDownload {
      opacity: 1;
      transform: translate3d(0, -70px, 0);

      @include respond-to(smartphone) {
        transform: translate3d(0, 0, 0);
      }
    }

    .app__download {
      transform: translate3d(-1%, -70px, 0);

      @include respond-to(smartphone) {
        transform: translate3d(-1%, 0, 0);
      }
    }
  }

  &.is-hidden {
    opacity: 0;
  }

  * {
    position: relative;
    z-index: 1;
  }

  .app__background {
    background: $dark-gray;
    content: '';
    height: 110%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translate3d(-5%, -5%, 0);
    transition: opacity 0.3s;
    width: 110%;
  }

  .app__logo {
    margin: 60px 0 40px;
    transform: translate3d(0, 0, 0);
    transition: transform .3s;

    @include respond-to(smartphone) {
      margin: 18px 0 16px;
    }

    img {
      height: 90px;
      width: 90px;

      @include respond-to(smartphone) {
        height: 60px;
        width: 60px;
      }
    }
  }

  .app__name,
  .app__nameDownload {
    @extend h4;
    align-items: center;
    display: flex;
    float: left;
    height: 60px;
    justify-content: center;
    overflow: hidden;
    padding: 0 10px;
    transform: translate3d(0, 0, 0);
    transition: color .3s, opacity .3s, transform .3s;
    width: 100%;

    @include respond-to(smartphone) {
      font-size: 10px;
    }

    span {
      display: block;
      display: -webkit-box;
      max-height: 40px;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  .app__description {
    color: $darkest-gray;
    display: -webkit-box;
    font-size: 12px;
    margin: 5px 25px 15px;
    max-height: 52px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    transform: translate3d(0, 0, 0);
    transition: color .3s, opacity .3s, transform .3s;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @include respond-to(smartphone) {
      font-size: 10px;
      margin: 5px 15px 15px;
    }
  }

  .app__nameDownload {
    align-items: flex-end;
    bottom: 0;
    color: #fff;
    font-size: 10px;
    left: 0;
    opacity: 0;
    padding-bottom: 10px;
    position: absolute;
  }

  .app__download {
    background: rgba(255,255,255,0);
    border-top: solid 1px rgba(255,255,255,0.2);
    display: table;
    left: 0;
    position: absolute;
    top: 100%;
    transform: translate3d(-1%, 0, 0);
    transition: transform .3s;
    width: 102%;



    a {
      display: table-cell;
      height: 70px;
      position: relative;
      transition: background-color .3s;

      @include respond-to(smartphone) {
        height: 35px;
      }

      &:hover {
        background: rgba(255,255,255,.1);
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      div {
        display: block;
        height: 100%;
        position: relative;

        &:before {
          content: '';
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);

          @include respond-to(smartphone) {
            transform: translate3d(-50%, -50%, 0) scale(.7);
          }
        }

        &:after {
          background: rgba(255,255,255,0.2);
          content: '';
          height: 100%;
          left: 100%;
          position: absolute;
          top: 0;
          width: 1px;
        }
      }
    }
  }

  .app__downloadPlay {
    div:before {
      @extend .svg-apps-android-logo;
      @extend .svg-apps-android-logo-dims;
    }
  }

  .app__downloadIOS {
    div:before {
      @extend .svg-apps-apple-logo;
      @extend .svg-apps-apple-logo-dims;
    }
  }

  .app__downloadWeb {
    div:before {
      @extend .svg-apps-chrome-logo;
      @extend .svg-apps-chrome-logo-dims;
    }
  }
}

.category {
  clear: both;
  height: 345px;
  margin-bottom: 50px;
  opacity: 1;
  position: relative;
  transition: opacity .3s;

  @include respond-to(smartphone) {
    padding: 80px 0 0;
    margin-bottom: 40px;
  }

  &.is-hidden {
    opacity: 0;
  }

  &.is-loading {
    opacity: 0 !important;
  }

  .category__background {
    background: $dark-gray;
    content: '';
    height: 345px;
    left: 0;
    position: absolute;
    top: 0;
    width: 32vw;

    @include respond-to(smartphone) {
      width: 100vw;
    }
  }

  .category__label {
    float: left;
    position: relative;
    width: 28vw;
    z-index: 1;

    @include respond-to(smartphone) {
      margin: 0 7vw;
      position: absolute;
      top: 0;
      width: 86vw;
    }

    h2 {
      color: #fff;
      float: left;
      padding: 65px 0 25px 45px;

      @include respond-to(smartphone) {
        align-items: center;
        display: flex;
        font-size: 22px;
        height: 90px;
        justify-content: center;
        padding: 0;
        max-width: calc(80vw - 75px);
      }
    }

    .button {
      clear: left;
      color: #fff;
      cursor: pointer;
      float: left;
      margin: 0 0 0 45px;

      @include respond-to(smartphone) {
        clear: none;
        float: right;
        margin: 28px 0 0;
        padding: 8px 20px;
      }

      &:hover {
        color: $blue;
        background: #fff;
        border-color: #fff;
      }
    }
  }

  .category__apps {
    @include respond-to(smartphone) {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      width: 100vw;
    }

    .category__appsScroll {
      @include respond-to(smartphone) {
        overflow: auto;
        padding: 10px 0 35px 0;
        width: calc(144px * 5 + 14vw);
      }
    }
  }

  .app {
    float: left;
    height: 305px;
    position: relative;
    margin-top: 20px;
    width: 13.666vw;

    @include respond-to(smartphone-large) {
      width: 22.777vw;

      &:nth-child(4), &:nth-child(5) {
        display: none;
      }
    }

    @include respond-to(smartphone) {
      height: 220px;
      margin-top: 0;
      width: 140px;

      &:first-child {
        margin-left: 7vw;
      }

      &:last-child {
        margin-right: 7vw;
      }

      &:nth-child(4), &:nth-child(5) {
        display: block;
      }
    }

    &:hover {
      .app__logo {
        transform: translate3d(0, 0, 0);
      }
    }

    .app__name {
      display: block;
      float: none;
      height: auto;
      padding: 0 25px;
      text-align: left;

      @include respond-to(smartphone) {
        font-size: 12px;
        padding: 0 15px;
      }
    }

    .app__logo {
      img {
        @include respond-to(smartphone) {
          height: 80px;
          width: 80px;
        }
      }
    }

    .app__download {
      a {
        @include respond-to(smartphone) {
          height: 50px;
        }
      }
    }
  }
}

.apps__chromecastApp {
  .section__inner {
    position: relative;
  }
}
