.offers__hero {
  height: 500px;

  .hero__imageBg {
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 100%;
  }

  h1 {
    color: $blue
  }

  p {
    color: $dark-gray;
  }

  .button:first-of-type {
    background-color: $blue;
    border: 1px solid $blue;
    color: white;
    margin-right: 10px;

    &:hover {
      background-color: #00CFFF;
      border-color: #00CFFF;
    }
  }

  .button {
    background-color: white;
    border: 1px solid $lighter-gray;
    color: $blue;

    &:hover {
      color: white;
      background-color: $blue;
      border-color: $blue;
    }
  }

  @media only screen and (max-width: 1400px) {
    .hero__imageBg {
      background-size: contain;
    }

    p {
      max-width: 50%;
    }
  }

  @include respond-to(smartphone) {
    height: 620px;

    .hero__imageBg {
      background-position: 100% 95%;
      background-size: 200% auto;
    }

    p {
      max-width: none;
    }
  }

  @media only screen and (max-width: 625px) {
    height: 550px;
  }

  @media only screen and (max-width: 500px) {
    height: 520px;
  }
}

.offers__heroSling {
  height: auto;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    height: 700px;
  }

  @media only screen and (max-width: 625px) {
    height: 650px;
  }

  @media only screen and (max-width: 500px) {
    height: 560px;
  }

  h1 {
    max-width: 50%;

    @include respond-to(smartphone) {
      max-width: 100%;
    }

    @media only screen and (max-width: 500px) {
      font-size: 40px;
    }
  }

  a.button {
    float: left;
    clear: both;

    &:nth-of-type(2) {
      display: none;
    }
  }

  .hero__buyLabel {
    clear: both;
    float: left;
    font-size: 14px;
  }
}

.offers__listing {
  background-color: $lightest-gray;
  min-height: 0;
}

.offers__listingSling {
  .offers__listingInner .listing__finePrint {
    font-size: 14px;
  }
}

.offers__listingInner {
  margin: 0 auto;
  max-width: 1000px;

  .listing__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include respond-to(smartphone) {
      display: block;
      text-align: center;

      h2 {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }

  .listing__filters {
    position: relative;
    top: 6px;
  }

  .listing__filter {
    @extend %thinType;
    border-bottom: 1px solid transparent;
    color: $dark-gray;
    cursor: pointer;
    display: inline-block;
    font-size: 21px;
    margin: 0 15px;
    transition: color $transition-sm, border-color $transition-sm;

    &:hover, &.is-active {
      color: $blue;
    }

    &.is-active {
      border-color: $blue;
    }

    @include respond-to(smartphone) {
      font-size: 16px;
      font-weight: $opensans-light;
    }
  }

  .listing__item {
    background: white;
    display: flex;
    margin: 15px 0;
    opacity: 1;
    transition: opacity $transition-med, box-shadow $transition-med;
    width: 100%;

    &.is-hidden {
      opacity: 0;
      transition: none;
    }

    &:hover,
    &:active {
      box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
    }
  }

  .listing__itemLink {
    align-items: center;
    background: $light-gray;
    display: flex;
    min-height: 200px;
    width: 66%;

    @include respond-to(smartphone) {
      height: auto;
      min-height: 0;
      width: 100%;
    }
  }

  .listing__itemImage {
    width: 100%;
  }

  .listing__itemCta {
    position: relative;
    width: 33%;

    @include respond-to(smartphone) {
      display: none;
    }
  }

  .listing__itemCtaInner {
    @include center(xy);
    text-align: center;
  }

  .listing__itemButton {
    display: inline-block;
    margin-bottom: 10px;
  }

  .listing__expiration {
    color: $dark-gray;
    font-size: 15px;
  }

  .listing__finePrint {
    @extend %normalType;
    color: $darker-gray;
    font-size: 11px;
    margin-top: 30px;
    text-align: center;
  }
}