h1 {
  @extend %titleCaps;
  color: white;
  font-size: 90px;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 95px;

  @include respond-to(tablet) {
    font-size: 70px;
    line-height: 80px;
  }

  @include respond-to(smartphone) {
    font-size: 50px;
    line-height: 1.1em;
  }
}

h2 {
  @extend %thinType;
  color: $darkest-gray;
  font-size: 35px;

  @include respond-to(smartphone) {
    font-size: 27px;
  }
}

h3 {
  @extend %thinType;
  color: $darker-gray;
  font-size: 30px;

  @include respond-to(smartphone) {
    font-size: 20px;
  }
}

h4 {
  @extend %smallCaps;
  color: $blue;
  font-size: 14px;

  @include respond-to(smartphone) {
    font-size: 12px;
  }
}

a {
  color: $blue;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #000;
  }
}

p {
  font-weight: $opensans-light;
  color: $dark-gray;
  font-size: 17px;

  @include respond-to(smartphone) {
    font-size: 13px;
    font-weight: $opensans-regular;
  }
}

.attribution {
  font-size: 12px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.highlighted {
  color: $blue;
}

%smallCaps {
  font-weight: $opensans-semibold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%titleCaps {
  font-family: $hero-font;
  text-transform: uppercase;
}

%thinType {
 font-family: $font-family;
 font-weight: $opensans-light;
}

%normalType {
  font-family: $font-family;
  font-weight: $opensans-regular;
}

%boldType {
  font-family: $font-family;
  font-weight: $opensans-bold;
}
