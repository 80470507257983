.tv {
  opacity: 1;
  transition: opacity .3s;

  &.is-hidden {
    opacity: 0;
  }
}

.tv__hero {
  .hero__videoBg {
    height: 100%;
    overflow: hidden;
    width: 100%;

    video {
      @include center(xy);
      // override vertical centering to avoid legal text
      // getting cut off at bottom
      @media only screen and (max-height: 800px) and (min-width: 1500px) {
        transform: translate(-50%, -60%);
      }
      @media only screen and (max-height: 1024px) and (min-width: 1440px) and (max-width: 1499px) {
        transform: translate(-50%, -54%);
      }
      @media only screen and (max-height: 700px) and (min-width: 1025px) {
        transform: translate(-50%, -58%);
      }
      @media only screen and (max-height: 699px) and (min-width: 1280px) {
        transform: translate(-50%, -66%);
      }
      
    }
  }

  .hero__mobileBg {
    background-position: top right;
  }

  .hero__side {
    display: none;
  }

  @include respond-to(smartphone-and-touch) {
    background: $blue;
    padding: 0;

    .hero__content {
      padding: 10vw;
    }

    .hero__side {
      display: block;
      min-height: calc((100vh - 68px) / 2);
    }
  }

  .touch & {
    height: auto;

    .hero__mobileBg {
      display: block;
    }

    .hero__side {
      width: 100%;
    }
  }
}

.tv__whatIs {
  @include respond-to(smartphone) {
    padding-bottom: 0;

    .section__image--mobile {
      position: relative;
      right: -10vw;
    }
  }

  .section__inner {
    margin: 0 auto;

    @include respond-to(smartphone) {
      display: block;
    }
  }

  p {
    @include respond-to(smartphone) {
      font-weight: $opensans-regular;
    }
  }

  .section__content {
    @include respond-to(tablet) {
      width: 40%;
    }

    @include respond-to(smartphone) {
      width: auto;
    }
  }

  .section__imageContainer {
    flex-grow: 1;
    height: 730px;
    position: relative;
    text-align: center;

    @include respond-to(smartphone) {
      height: auto;
    }
  }

  img {
    height: 730px;
    width: 888px;

    @media only screen and (min-width: 769px) and (max-width: 1400px) {
      height: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50vw;
    }

    @include respond-to(smartphone) {
      height: auto;
      position: static;
      width: 100%;
    }
  }
}

.tv__tour {
  background-color: $lightest-gray;
  background-size: contain;
  min-height: 745px;
  padding-bottom: 0;

  .section__inner {
    height: $section-height - 120;
    justify-content: flex-end;
  }

  .section__imageContainer {
    @include respond-to(smartphone-and-touch) {
      opacity: 0;
    }

    img {
      width: 903px;
      height: 700px;
    }
  }

  .section__videoBg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-750px, -50%, 0);
    width: 1100px;
    height: 764px;

    &:after {
      box-shadow: inset 0 0 50px #f3f3f3;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @media only screen and (min-width: 769px) and (max-width: 1560px) {
      height: 48vw;
      max-height: 625px;
      max-width: 900px;
      width: 70vw;
      transform: translate3d(-50vw, -50%, 0);
    }

    video {
      @include center(y);

      @media only screen and (min-width: 1280px) and (max-width: 1560px) {
        max-width: 900px;
      }
    }

    .section__videoBgAttribution {
      @extend .attribution;
      bottom: 215px;
      color: $light-gray;
      left: 84px;
      position: absolute;

      @media only screen and (min-width: 1280px) and (max-width: 1560px) {
        bottom: 275px;
        left: 63px;
      }

      @include respond-to(tablet) {
        bottom: 296px;
        left: 185px;
      }

      @media only screen and (min-width: 769px) and (max-width: 1560px) {
        bottom: 100px;
        left: 5vw;
      }
    }
  }

  .section__content {
    margin-top: -90px;

    @include respond-to(tablet) {
      width: 300px;
    }
  }

  @include respond-to(smartphone-and-touch) {
    height: 700px;
    padding: 0;

    .section__inner {
      position: static;
    }

    h2 {
      left: 30px;
      position: absolute;
      top: 30px;
      z-index: 1;
    }

    .section__content {
      margin-bottom: 0;
      margin-top: 0;
      width: 100%;
    }

    .section__tourStepContent {
      z-index: 0;
    }

    .section__videoBg {
      display: none;
    }

    .section__tour {
      margin-top: 0;
      position: static;
    }

    .section__inner.section__inner--right {
      align-items: flex-end;
      height: 100%;
      margin: 0 -30px;
    }
  }
}

.tv__apps {
  overflow: visible;
  position: relative;
  z-index: 1;

  @include respond-to(smartphone) {
    display: block;
    // height: 600px;
    z-index: 1;

    p {
      display: none;
    }

    .section__mobileBg {
      z-index: -1;
    }
  }

  .section__inner {
    height: $section-height - 120;
    justify-content: flex-start;

    @include respond-to(smartphone) {
      height: 125vw;
      min-height: 550px;
      position: static;

      &.section__inner--left {
        margin-left: 0;
      }
    }
  }

  .section__content {
    background: white;
    box-shadow: 0 17px 50px 0 rgba(0,0,0,.19);
    padding: 30px 40px;
    width: 500px;

    @include respond-to(tablet) {
      margin-top: -250px;
    }

    @include respond-to(smartphone) {
      background: none;
      box-shadow: none;
      margin-top: 0;
      padding: 0;
      width: auto;
    }
  }

  .section__appsImage {
    bottom: -169px;
    height: 784px;
    position: absolute;
    right: 0;
    width: 870px;


    @include respond-to(tablet) {
      bottom: -117px;
      height: 544px;
      width: auto;
    }

    @include respond-to(smartphone) {
      bottom: -78px;
      height: 362px;
      width: auto;
    }
  }

  .section__appsStores {
    bottom: 182px;
    left: calc(10% + 18px);
    position: absolute;

    @media only screen and (min-width: 2000px) {
      left: calc(20% + 11px);
    }

    @media only screen and (max-width: 1400px) {
      left: calc(5% + 21px);
    }

    @include respond-to(tablet) {
      bottom: 308px;
    }

    @include respond-to(smartphone) {
      display: none;
    }

    .section__appsStoresAppStore {
      @extend .svg-chromecast-app-applestore;
      @extend .svg-chromecast-app-applestore-dims;
      float: left;
      transform: scale(1.5);
      transform-origin: 0 0;
    }

    .section__appsStoresPlayStore {
      @extend .svg-chromecast-app-googleplay;
      @extend .svg-chromecast-app-googleplay-dims;
      float: left;
      margin-left: 52px;
      transform: scale(1.5);
      transform-origin: 0 0;
    }
  }
}

.tv__learn {
  background-color: $lightest-gray;
  min-height: auto;
  min-height: initial;
  padding-top: 115px;

  @include respond-to(smartphone) {
    padding: 100px 30px 20px;

    p, .button {
      display: none;
    }
  }

  .section__content {
    text-align: center;
    width: 700px;

    @include respond-to(desktop) {
      h2 {
        padding: 0 5%;
      }
    }

    @include respond-to(smartphone) {
      text-align: auto;
      width: auto;
    }
  }
}

.tv__cards {
  background-color: $lightest-gray;
  min-height: auto;
  min-height: initial;

  .svg-tv-smarter-mirror-android-white ~ .card__animation {
    top: 22px;
  }
}

.tv__buy {

  .section__inner.section__inner--right {
    justify-content: center;
    margin: 0 auto;

    @include respond-to(tablet) {
      &.section__inner--right {
        margin-right: 0;
      }
    }

    @include respond-to(smartphone) {
      display: block;
    }
  }

  h2 {
    @extend h1;
    color: $blue;
  }

  .section__content {
    width: 500px;

    @include respond-to(tablet) {
      width: 420px;
    }

    @include respond-to(smartphone) {
      position: absolute;
      top: 30px;
      width: 90%;
    }
  }

  .section__buyButtonPrice {
    @extend h2;
    color: $dark-gray;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    text-transform: none;
  }

  .section__imageContainer {
    height: 730px;
    position: relative;
    text-align: center;

    @media only screen and (min-width: 769px) and (max-width: 1300px) {
      flex-grow: 1;
    }

    @include respond-to(smartphone) {
      display: block;
      height: auto;
    }
  }

  img {
    height: 730px;
    width: 910px;

    @media only screen and (min-width: 769px) and (max-width: 1300px) {
      height: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50vw;
    }

    @include respond-to(smartphone) {
      position: static;
      height: auto;
      margin-top: 150px;
      width: 100%;
    }
  }
}
