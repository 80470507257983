footer {
  background: $lightest-gray;
  text-align: center;
  width: 100%;

  .footer__emailBanner {
    background: $blue;
    display: none;
    height: 100px;

    p {
      @extend h3;
      color: white;
      line-height: 100px;
      margin: 0;
    }
  }

  .footer__chromeLinksInner, .footer__googleLinksInner,
  .footer__emailBannerInner {
    display: inline-block;
    max-width: 1000px;
    padding: 0 30px;
    text-align: left;
    width: 100%;
  }

  .footer__googleLinksInner {
    max-width: 825px;

    @include respond-to(smartphone-large) {
      max-width: 100%;
    }
  }

  .footer__locale-switcher {
    display: inline-block;

    @include respond-to(smartphone-large) {
      float: left;
      margin: 10px 30px 20px 28px
    }

    select {
      border: none;
      border-radius: 0;
      color: $light-gray;
      display: inline-block;
      font: 600 12px/1 $font-family;
      margin: -3px 0 0;
      padding: 0 0 0 5px;
      height: 30px;
      width: 175px;
    }
  }

  .footer__logo {
    @extend .svg-chromecast-logo-grey;
    @extend .svg-chromecast-logo-grey-dims;
    display: inline-block;
    margin-left: -5px;
    margin-right: 60px;

    @include respond-to(smartphone-large) {
      display: block;
    }
  }

  .footer__hdmi {
    @extend .svg-hdmi;
    @extend .svg-hdmi-dims;
    margin: 0 0 5px -5px;
  }

  .footer__chromeLinks {
    margin-bottom: 50px;
    padding-top: 50px;

    h5 {
      color: $darker-gray;
      font-size: 13px;
      text-transform: uppercase;
    }

    ul {
      @extend h4;
      color: $darker-gray;
      display: inline-block;
      font-size: 13px;
      line-height: 2em;
      list-style-type: none;
      margin-right: 30px;
      margin-top: 10px;
      padding: 0;
      vertical-align: top;
      
      @media only screen and (min-width: 1024px) {
        max-width: 26%;
        line-height: 1.75em;

        &:nth-child(4) {
          margin-right: 0;
        }
      }

      @include respond-to(smartphone) {
        display: block;
        margin: 20px 0;
      }
    }

    li {
      padding-bottom: 12px;    
      line-height: 1.55em;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        padding-bottom: 0;
      }

      a {
        @extend %normalType;
        color: $light-gray;
        letter-spacing: normal;
        text-decoration: none;
        text-transform: none;
        transition: color 0.2s;

        &:hover {
          color: $darkest-gray;
        }
      }
    }
  }

  .footer__finePrint {
    @extend h3;
    font-size: 11px;
    margin-top: 75px;
    text-align: left;
  }

  .footer__googleLinks {
    background: white;
    line-height: 2em;
    padding: 10px 0;

    a {
      @extend h4;
      color: $light-gray;
      font-size: 12px;
      margin: 0 10px;
      text-decoration: none;
      transition: color 0.2s;

      @include respond-to(smartphone) {
        display: block;
        margin: 0;
      }
    }

    .footer__googleLink:first-child a {
      margin-left: 0;
    }
  }
}