.learn--tv {
  opacity: 1;
  transition: opacity .3s;

  &.is-hidden {
    opacity: 0;
  }
}

.learn__hero {
  background: $blue;

  @include respond-to(smartphone) {
    height: auto;
    min-height: initial;
  }

  h1 {
    @media only screen and (min-width: 1512px) {
      max-width: 740px;
    }

    @media only screen and (max-width: 1512px) and (min-width: 1280px) {
      max-width: 500px;
    }
  }
}

.learn__hero--landing {
  .hero__side {
    &:hover {
      &:before {
        opacity: 0;
      }
    }

    &:before {
      background: #000;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.2;
      position: absolute;
      pointer-events: none;
      top: 0;
      transition: opacity .3s;
      width: 100%;
    }

    .hero__imageBg {
      @include respond-to(smartphone-large) {
        background-position: 50% 68%;
      }
    }

    * {
      position: relative;
      z-index: 1;
    }
  }

  .hero__arrow {
    display: none;
  }
}

.learn__steps {
  background: $lightest-gray;

  .section__steps {
    @include respond-to(smartphone) {
      margin: 50px 0 0;
    }
  }
}

.learn__videos {
  background: $lightest-gray;
  position: relative;
  overflow: hidden;
}

.learn--tv {
  .learn__videos {
    .cards {
      position: relative;
      z-index: 1;
    }
  }
}

.learn--speakers {
  .section--videoCards {
    @include respond-to(smartphone-large) {
      padding: 0 0 60px;
    }
  }
}

.learn__compatibility {
  padding: 150px 0;

  @include respond-to(smartphone) {
    background: none !important;
    padding: 40px 10%;
    overflow: visible;
  }

  .section__mobileBg {
    background-color: rgba(0,0,0,0.05);
    background-blend-mode: multiply;
    background-size: 100% auto;
  }

  .section__content {
    float: left;
    width: 500px;

    @include respond-to(smartphone) {
      float: none;
      width: auto;
    }
  }

  h2 {
    margin-bottom: 40px;

    @include respond-to(smartphone) {
      color: #fff;
      min-height: 240px;
    }
  }

  h4 {
    color: $dark-gray;
  }

  .button {
    margin-top: 50px;
  }

  .section__disclaimer {
    clear: left;
    font-size: 12px;
    padding-top: 20px;

    @include respond-to(smartphone) {
      font-size: 10px;
    }
  }

  a.button {
    margin-bottom: 0;

    @include respond-to(smartphone) {
      font-size: 10px;
      margin-top: 30px;
      padding: 10px 15px 10px;
    }
  }
}