$font-family: 'Open Sans', 'Helvetica', arial, sans-serif;
$opensans-light: 300;
$opensans-regular: 400;
$opensans-semibold: 600;
$opensans-bold: 700;
$hero-font: 'Anton', 'Helvetica', arial, sans-serif;

$blue: #00AFFF;
$darkest-gray: #333333;
$darker-gray: #4d4d4d;
$dark-gray: #797979;
$light-gray: #b8b8b8;
$lighter-gray: #cdcdcd;
$lightest-gray: #f3f3f3;
$explore-gray: #848484;

$easing: cubic-bezier(.55,0,.1,1);

$header-height: 68px;
$section-height: 815px;

$transition-sm: 0.3s;
$transition-med: 0.5s;

$long-winded-langs: 'html[lang=da_dk] &,
                  html[lang=de_CH] &,
                  html[lang=de_DE] &,
                  html[lang=es_ES] &,
                  html[lang=fr_BE] &,
                  html[lang=fr_CA] &,
                  html[lang=fr_CH] &,
                  html[lang=fr_FR] &,
                  html[lang=it_CH] &,
                  html[lang=it_IT] &,
                  html[lang=nl_BE] &,
                  html[lang=nl_NL] &,
                  html[lang=no_NO] &,
                  html[lang=sv_SE] &,
                  html[lang=pt_PT] &,
                  html[lang=en_GB] &';
